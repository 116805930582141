<template>
  <div class="messenger-top-div">
    <div v-show="loadings.messages === true" class="loader-box zoom">
      <loading :loading="true" />
    </div>

    <div style="background: #f8f9fa" class="messenger-css">
      <div class="container-fluid bg-white p-0">
        <div class="messenger-box-shadow">
          <div
            class="row h-100 no-gutters"
            v-show="$_.has(sconv, '_id') || convs.NORMAL.length > 0"
          >
            <div class="col-3" :style="{ background: '#f8f8f8', height: isSafari ? '50px': false }">
              <div class="messages-list">
                <!--jimir3x-->

                <div
                  class="
                    messages-list-header
                    d-flex
                    align-items-center align-items-center
                  "
                >
                  <div class="messages-top-section-left-1">
                    <h3 class="title">{{ $t("messenger.messages") }}</h3>
                    <div
                      class="d-inline-flex align-items-center"
                      style="flex: 1"
                    >
                      <input spellcheck="true"
                        type="text"
                        class="
                          form-control form-control-md
                          messenger-search-input
                        "
                        style="width: 100% !important"
                        :placeholder="$t('messenger.search')"
                        @keydown="searchDelay(onSearch)"
                        v-model="search"
                      />
                      <div class="icon" style="position: absolute">
                        <a>
                          <img
                            src="/img/messenger-search-icon.svg"
                            class="img-fluid"
                            alt=""
                            width="17px"
                            style="position: relative; top: -2px"
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    class="messages-top-section-left-2 messages-list-content"
                  >
                    <div style="background: #fff; zoom: 1.2">
                      <ul class="nav buyer-seller-message-list">
                        <li>
                          <a
                            @click="changeType('NORMAL')"
                            :class="type === 'NORMAL' ? 'active' : ''"
                            href="javascript:void(0)"
                          >
                            {{ $t("buttons.users") }}
                            <span v-if="badge > 0"> ({{ badge }}) </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            @click="changeType('FAVORITE')"
                            :class="type === 'FAVORITE' ? 'active' : ''"
                          >
                            {{ $t("messenger.favs") }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="messages-list-content"
                  ref="convsList"
                  @scroll="onScrollConvs"
                >
                  <transition-group style="100vh" name="flip-list">
                    <div
                      @click="changeconv(item._id)"
                      :class="item._id === conversation_id ? 'active' : ''"
                      class="item cursor-pointer"
                      v-for="item in tconvs"
                      :key="item._id"
                    >
                      <div class="row">
                        <div class="col-md-2 pr-0">
                          <div class="person-info">
                            <div class="d-flex align-items-center">
                              <b-avatar :src="getAvatar($_.get(item, 'to.avatar', null), 50)" size="50"></b-avatar>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-10 pl-0">
                          <div class="person-info">
                            <div class="d-flex align-items-center">
                              <span
                                class="person-info-name"
                                style="text-transform: capitalize"
                              >
                                {{ item.to.name }} {{ item.to.surname }}
                              </span>
                              <img
                                v-if="item.muted"
                                class="ml-2"
                                src="/img/alert-icon-off.svg"
                                style="height: 15px"
                                alt=""
                              />
                            </div>
                            <span class="item-date"
                              ><HRTime :datetime="item.updated_at" />
                              <img
                                v-if="checkUnread(item)"
                                src="/img/read-message.svg"
                                alt=""
                                class="img-fluid ml-2"
                                width="10"
                                style="margin-top: 1px"
                              />
                            </span>
                          </div>

                          <div
                            class="search-inmessage-text"
                            v-if="search != ''"
                            v-html="
                              $_.get(item, 'last_message.content.message', ' ')
                            "
                          ></div>

                          <div
                            v-else-if="!!item.typing"
                            class="item-last-message"
                          >
                            <img
                              src="@/assets/img/gifs/typing.gif"
                              height="15"
                              width="15"
                            />
                          </div>
                          <div v-else class="item-last-message">
                            {{
                              stripHtml(
                                $_.get(
                                  item,
                                  "last_message.content.message",
                                  " "
                                )
                              )
                            }}
                          </div>

                          <b-dropdown
                            right
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            class="messages-edit-list-icon"
                          >
                            <template #button-content class="p-0">
                              <img
                                src="/img/open-card-footer-menu.svg"
                                class="img-fluid"
                                alt=""
                                style="width: 16px; height: 16px"
                              />
                            </template>
                            <b-dropdown-item @click="delconv(item._id)">{{
                              $t("messenger.deletechat")
                            }}</b-dropdown-item>
                            <b-dropdown-item
                              v-if="!checkInFavorites(item._id)"
                              @click="onAddToMyFavorites(item._id)"
                              >{{ $t("messenger.markfav") }}</b-dropdown-item
                            >
                            <b-dropdown-item
                              v-if="checkInFavorites(item._id)"
                              @click="onRemoveFromMyFavorites(item._id)"
                              >{{ $t("messenger.removefav") }}</b-dropdown-item
                            >
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </transition-group>
                </div>
              </div>
            </div>

            <div
              :class="{ 'col-9': hideDetails, 'col-6': !hideDetails }"
              :style="{ border: '1px solid #e8e8e899',  height: isSafari ? ( hideDetails ? '85vh' : '90vh' ) : '80vh' }"
            >
              <div class="messages-person">
                <div
                  class="
                    messages-person-header
                    justify-content-between
                    align-items-center
                  "
                >
                  <div
                    v-if="$_.has(sconv, 'to')"
                    class="d-flex align-items-center"
                  >
                    <img
                      :src="getAvatar(sconv.to.avatar, 34)"
                      style="width: 34px"
                      width="34"
                      height="34"
                      alt=""
                    />
                    <h3 class="title" style="text-transform: capitalize">
                      {{ sconv.to.name }} {{ sconv.to.surname }}
                      <img
                        v-if="sconv.muted"
                        class="ml-2"
                        src="/img/alert-icon-off.svg"
                        style="height: 15px"
                        alt=""
                      />
                      <img
                        v-if="sconv.to.blocked"
                        class="ml-2"
                        src="/img/cancel-plan.svg"
                        style="height: 15px"
                      />
                    </h3>
                  </div>

                  <div class="d-flex align-items-center">
                    <b-dropdown
                      right
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      class="dropdown-2-a"
                      v-bind:style="{
                        // background: hideDetails ? 'purple' : 'green',
                        marginRight: hideDetails ? '16px' : 0,
                      }"
                    >
                      <template
                        #button-content
                        class="p-0"
                        style="font-size: 12px; color: #a7a7a7"
                      >
                        <!--<v-icon :icon="['fas', 'ellipsis-v']"></v-icon> -->
                        <span style="font-size: 14px; color: #a7a7a7">{{
                          $t("messenger.actions")
                        }}</span>
                        <img
                          src="/img/down-arrow.svg"
                          class="img-fluid ml-2"
                          alt=""
                          style="width: 16px; height: 12x"
                        />
                      </template>
                      <b-dropdown-item
                        v-if="!$_.get(sconv, 'muted')"
                        @click="mute(sconv._id)"
                        class="mt-10 align-items-center dropdown-item"
                        style="padding: 0px important"
                      >
                        <div style="width: auto" class="">
                          <svg
                            class="ml-1 mr-3"
                            style="width: 24px; height: 24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M12,4L9.91,6.09L12,8.18M4.27,3L3,4.27L7.73,9H3V15H7L12,20V13.27L16.25,17.53C15.58,18.04 14.83,18.46 14,18.7V20.77C15.38,20.45 16.63,19.82 17.68,18.96L19.73,21L21,19.73L12,10.73M19,12C19,12.94 18.8,13.82 18.46,14.64L19.97,16.15C20.62,14.91 21,13.5 21,12C21,7.72 18,4.14 14,3.23V5.29C16.89,6.15 19,8.83 19,12M16.5,12C16.5,10.23 15.5,8.71 14,7.97V10.18L16.45,12.63C16.5,12.43 16.5,12.21 16.5,12Z"
                            />
                          </svg>
                          {{ $t("messenger.mute") }}
                        </div>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-else
                        @click="unmute(sconv._id)"
                        class="dropdown-item"
                        style="padding: 0 important"
                      >
                        <div style="width: 100%" class="">
                          <svg
                            class="ml-1 mr-3"
                            style="width: 24px; height: 24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z"
                            />
                          </svg>
                          <span class="ml-2 mt-2"
                            >{{ $t("messenger.unmute") }}
                          </span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="dropdown-item"
                        style="padding: 0 important"
                        @click="() => $refs.reportModal.open()"
                      >
                        <div style="width: 100%" class="">
                          <svg
                            class="ml-1 mr-3"
                            style="width: 24px; height: 24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M10 4A4 4 0 0 1 14 8A4 4 0 0 1 10 12A4 4 0 0 1 6 8A4 4 0 0 1 10 4M10 14C14.42 14 18 15.79 18 18V20H2V18C2 15.79 5.58 14 10 14M20 12V7H22V13H20M20 17V15H22V17H20Z"
                            />
                          </svg>
                          {{ $t("messenger.report") }}
                        </div>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="
                          $_.has(sconv, 'to') &&
                          $_.get(sconv, 'to.blocked') === false
                        "
                        @click="block(sconv.to.uid)"
                        class="dropdown-item"
                        style="padding: 0 important"
                      >
                        <div style="width: 100%" class="">
                          <svg
                            class="ml-1 mr-3"
                            style="width: 24px; height: 24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M10 4A4 4 0 0 0 6 8A4 4 0 0 0 10 12A4 4 0 0 0 14 8A4 4 0 0 0 10 4M17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13M10 14C5.58 14 2 15.79 2 18V20H11.5A6.5 6.5 0 0 1 11 17.5A6.5 6.5 0 0 1 11.95 14.14C11.32 14.06 10.68 14 10 14M17.5 14.5C19.16 14.5 20.5 15.84 20.5 17.5C20.5 18.06 20.35 18.58 20.08 19L16 14.92C16.42 14.65 16.94 14.5 17.5 14.5M14.92 16L19 20.08C18.58 20.35 18.06 20.5 17.5 20.5C15.84 20.5 14.5 19.16 14.5 17.5C14.5 16.94 14.65 16.42 14.92 16Z"
                            />
                          </svg>
                          {{ $t("messenger.block") }}
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-else
                        @click="unblock(sconv.to.uid)"
                        class="dropdown-item"
                        style="padding: 0 important"
                      >
                        <div style="width: 100%" class="">
                          <svg
                            class="ml-1 mr-3"
                            style="width: 24px; height: 24px"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M10,17L13,20H3V18C3,15.79 6.58,14 11,14L12.89,14.11L10,17M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4Z"
                            />
                          </svg>
                          {{ $t("messenger.unblock") }}
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>

                    <button
                      v-if="hideDetails"
                      @click="hideDetails = !hideDetails"
                      class="btn btn-outline-primary btn-sm"
                      style="
                        padding: 1px 10px;
                        font-size: 16px;
                        border: 1px solid;
                      "
                    >
                      <svg
                        style="width: 24px; height: 24px"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"
                        />
                      </svg>
                      <!-- {{ $t("messenger.show-d") }} -->
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="messages-screen"
                ref="scroller"
                @scroll="onScrollMessage"
              >
                <!--jimir3x-->
                <div
                  :style="
                    this.messagesMediaModal.visible
                      ? 'display: flex'
                      : 'display: none'
                  "
                  id="messages-media-modal"
                  class="messages-media-modal"
                >
                  <div
                    v-if="this.messagesMediaModal.content != null"
                    id="messages-media-modal__inner"
                    class="messages-media-modal__inner"
                  >
                    <div
                      class="messages-media-modal__close"
                      v-on:click="onCloseMediaModal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#000000"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path
                          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                        />
                      </svg>
                    </div>

                    <a
                      v-if="
                        this.messagesMediaModal.content.filename !==
                        '__record__.mp3'
                      "
                      :href="
                        CDN_FILE_PREFIX + this.messagesMediaModal.content.code
                      "
                      class="messages-media-modal__download"
                    >
                      <img src="/img/download-grey.svg" />
                    </a>

                    <div
                      class="messages-media-modal__prev"
                      v-bind:class="{ disabled: messagesMediaModal.index == 0 }"
                      v-on:click="onMediaModalPrev"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="#000000"
                      >
                        <path
                          d="M0 0h24v24H0V0z"
                          fill="none"
                          opacity=".87"
                        ></path>
                        <path
                          d="M16.62 2.99c-.49-.49-1.28-.49-1.77 0L6.54 11.3c-.39.39-.39 1.02 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"
                        ></path>
                      </svg>
                    </div>
                    <div
                      class="messages-media-modal__next"
                      v-bind:class="{
                        disabled:
                          messagesMediaModal.index ==
                          messagesMediaModal.media.length - 1,
                      }"
                      v-on:click="onMediaModalNext"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="#000000"
                      >
                        <path
                          d="M24 24H0V0h24v24z"
                          fill="none"
                          opacity=".87"
                        ></path>
                        <path
                          d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
                        ></path>
                      </svg>
                    </div>

                    <img
                      v-if="
                        ['jpeg', 'jpg', 'png'].includes(
                          messagesMediaModal.content.ext
                        )
                      "
                      :src="CDN_IMAGE_PREFIX + messagesMediaModal.content.code"
                    />
                    <video
                      v-else-if="
                        ['webm', 'mp4'].includes(messagesMediaModal.content.ext)
                      "
                      controls
                    >
                      <source
                        :src="
                          CDN_VIDEO_PREFIX + messagesMediaModal.content.code
                        "
                      />
                      {{ $t("messenger.video-alert") }}
                    </video>
                    <audio
                      v-else-if="
                        ['mp3'].includes(messagesMediaModal.content.ext)
                      "
                      controls
                    >
                      <source
                        :src="
                          CDN_VIDEO_PREFIX + messagesMediaModal.content.code
                        "
                        type="audio/mpeg"
                      />
                      {{ $t("messenger.audio-alert") }}
                    </audio>
                    <img v-else src="@/assets/img/file.png" />
                  </div>
                </div>
                <!--jimir3x-->

                <div class="chat">
                  <div
                    v-for="item in messages"
                    :id="'message-' + item.id"
                    :key="'message-' + item.id"
                  >
                    <div v-if="!item.from_me" class="messages-screen-item">
                      <div class="content d-flex align-items-end w-100">
                        <b-dropdown
                          right
                          size="lg"
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                          class="icon-answer-message-opposite dropdown-2-a"
                        >
                          <template #button-content class="p-0">
                            <!--<v-icon :icon="['fas', 'ellipsis-v']"></v-icon> -->
                            <img
                              src="/img/down-arrow.svg"
                              class="img-fluid"
                              alt=""
                              style="width: 16px; height: 16px"
                            />
                          </template>
                          <b-dropdown-item
                            href="#"
                            class="pl-0 mr-3"
                            style="padding: 0 important; width: 100%"
                            @click="setReply(item.id)"
                          >
                            <div style="width: 100%" class="">
                              <svg
                                class="ml-1 mr-3"
                                style="width: 20px; height: 20px"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M10,9V5L3,12L10,19V14.9C15,14.9 18.5,16.5 21,20C20,15 17,10 10,9Z"
                                />
                              </svg>

                              <span class="messenger-action-texts">
                                {{ $t("buttons.reply") }}</span
                              >
                            </div>

                            <div style="width: 100%" class=""></div>
                          </b-dropdown-item>
                        </b-dropdown>

                        <img
                          :src="getAvatar($_.get(sconv, 'to.avatar', null), 42)"
                          width="42"
                          height="42"
                          alt=""
                          class="rounded-circle"
                        />
                        <div
                          :id="'message-' + item.id"
                          class="
                            chat-message chat-message-not-me
                            talk-bubble
                            tri-right
                            round
                            right-in
                            not-me-bubble
                          "
                        >
                          <div
                            class="text-right dropdown-message-icon"
                            style="height: 10px; padding-top: 5px"
                          >
                            <b-dropdown
                              right
                              size="lg"
                              variant="link"
                              toggle-class="text-decoration-none"
                              no-caret
                              class="icon-answer-message dropdown-2-a"
                            >
                              <template #button-content class="p-0">
                                <!--<v-icon :icon="['fas', 'ellipsis-v']"></v-icon> -->
                                <img
                                  src="/img/down-arrow.svg"
                                  class="img-fluid"
                                  alt=""
                                  style="width: 16px; height: 16px"
                                />
                              </template>
                              <b-dropdown-item
                                href="#"
                                class="pl-0 mr-3"
                                style="padding: 0 important; width: 100%"
                                @click="setReply(item.id)"
                              >
                                <div style="width: 100%" class="">
                                  <svg
                                    class="ml-1 mr-3"
                                    style="width: 20px; height: 20px"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M10,9V5L3,12L10,19V14.9C15,14.9 18.5,16.5 21,20C20,15 17,10 10,9Z"
                                    />
                                  </svg>

                                  <span class="messenger-action-texts">
                                    {{ $t("buttons.reply") }}</span
                                  >
                                </div>
                              </b-dropdown-item>
                              <!--<b-dropdown-item
                                    class="pl-3 mr-3"
                                    style="padding: 0 important"
                                    @click="deleteMessage(item.id)"
                                >
                                    Delete</b-dropdown-item
                                > -->
                            </b-dropdown>
                          </div>
                          <!--jimir3x-->
                          <div
                            v-if="
                              $_.size($_.get(item, 'content.files', [])) == 1
                            "
                            class="message-media message-media--1"
                          >
                            <div
                              class="message-media--1__inner"
                              v-on:click="
                                onClickMediaItem(
                                  $_.get(item, 'content.files', []),
                                  0
                                )
                              "
                            >
                              <img
                                v-if="
                                  ['jpeg', 'jpg', 'png'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              />

                              <video
                                v-else-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                />
                                {{ $t("messenger.video-alert") }}
                              </video>

                              <audio
                                v-else-if="
                                  ['mp3'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>

                              <img v-else src="@/assets/img/file.png" />

                              <a
                                v-if="
                                  $_.get(
                                    item,
                                    'content.files',
                                    []
                                  )[0].filename.indexOf('__record__.mp3') !== -1
                                "
                                :href="
                                  CDN_FILE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>

                              <div
                                class="layer"
                                v-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                              />
                            </div>
                          </div>
                          <div
                            v-if="
                              $_.size($_.get(item, 'content.files', [])) == 2
                            "
                            class="message-media message-media--2"
                          >
                            <div
                              class="message-media--2__left"
                              v-on:click="
                                onClickMediaItem(
                                  $_.get(item, 'content.files', []),
                                  0
                                )
                              "
                            >
                              <img
                                v-if="
                                  ['jpeg', 'jpg', 'png'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              />

                              <video
                                v-else-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                />
                                {{ $t("messenger.video-alert") }}
                              </video>

                              <audio
                                v-else-if="
                                  ['mp3'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>

                              <img v-else src="@/assets/img/file.png" />

                              <a
                                v-if="
                                  $_.get(
                                    item,
                                    'content.files',
                                    []
                                  )[0].filename.indexOf('__record__.mp3') !== -1
                                "
                                :href="
                                  CDN_FILE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>

                              <div
                                class="layer"
                                v-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                              />
                            </div>
                            <div
                              class="message-media--2__right"
                              v-on:click="
                                onClickMediaItem(
                                  $_.get(item, 'content.files', []),
                                  1
                                )
                              "
                            >
                              <img
                                v-if="
                                  ['jpeg', 'jpg', 'png'].includes(
                                    $_.get(item, 'content.files', [])[1].ext
                                  )
                                "
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  $_.get(item, 'content.files', [])[1].code
                                "
                              />

                              <video
                                v-else-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[1].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                />
                                {{ $t("messenger.video-alert") }}
                              </video>

                              <audio
                                v-else-if="
                                  ['mp3'].includes(
                                    $_.get(item, 'content.files', [])[1].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>

                              <img v-else src="@/assets/img/file.png" />

                              <a
                                v-if="
                                  $_.get(
                                    item,
                                    'content.files',
                                    []
                                  )[1].filename.indexOf('__record__.mp3') !== -1
                                "
                                :href="
                                  CDN_FILE_PREFIX +
                                  $_.get(item, 'content.files', [])[1].code
                                "
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>

                              <div
                                class="layer"
                                v-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[1].ext
                                  )
                                "
                              />
                            </div>
                          </div>
                          <div
                            v-if="
                              $_.size($_.get(item, 'content.files', [])) == 3
                            "
                            class="message-media message-media--3"
                          >
                            <div
                              class="message-media--3__left"
                              v-on:click="
                                onClickMediaItem(
                                  $_.get(item, 'content.files', []),
                                  0
                                )
                              "
                            >
                              <img
                                v-if="
                                  ['jpeg', 'jpg', 'png'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              />

                              <video
                                v-else-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                />
                                {{ $t("messenger.video-alert") }}
                              </video>

                              <audio
                                v-else-if="
                                  ['mp3'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>

                              <img v-else src="@/assets/img/file.png" />

                              <a
                                v-if="
                                  $_.get(
                                    item,
                                    'content.files',
                                    []
                                  )[0].filename.indexOf('__record__.mp3') !== -1
                                "
                                :href="
                                  CDN_FILE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>

                              <div
                                class="layer"
                                v-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                              />
                            </div>
                            <div class="message-media--3__right">
                              <div
                                class="message-media--3__right-top"
                                v-on:click="
                                  onClickMediaItem(
                                    $_.get(item, 'content.files', []),
                                    1
                                  )
                                "
                              >
                                <img
                                  v-if="
                                    ['jpeg', 'jpg', 'png'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  :src="
                                    CDN_IMAGE_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                />

                                <video
                                  v-else-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[1].code
                                    "
                                  />
                                  {{ $t("messenger.video-alert") }}
                                </video>

                                <audio
                                  v-else-if="
                                    ['mp3'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[1].code
                                    "
                                    type="audio/mpeg"
                                  />
                                  {{ $t("messenger.audio-alert") }}
                                </audio>

                                <img v-else src="@/assets/img/file.png" />

                                <a
                                  v-if="
                                    $_.get(
                                      item,
                                      'content.files',
                                      []
                                    )[1].filename.indexOf('__record__.mp3') !==
                                    -1
                                  "
                                  :href="
                                    CDN_FILE_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                >
                                  <img
                                    src="/img/download-grey.svg"
                                    class="img-fluid"
                                    style="width: 20px; margin-left: 10px"
                                    alt=""
                                  />
                                </a>

                                <div
                                  class="layer"
                                  v-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                />
                              </div>
                              <div
                                class="message-media--3__right-bot"
                                v-on:click="
                                  onClickMediaItem(
                                    $_.get(item, 'content.files', []),
                                    2
                                  )
                                "
                              >
                                <img
                                  v-if="
                                    ['jpeg', 'jpg', 'png'].includes(
                                      $_.get(item, 'content.files', [])[2].ext
                                    )
                                  "
                                  :src="
                                    CDN_IMAGE_PREFIX +
                                    $_.get(item, 'content.files', [])[2].code
                                  "
                                />

                                <video
                                  v-else-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[2].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[2].code
                                    "
                                  />
                                  {{ $t("messenger.video-alert") }}
                                </video>

                                <audio
                                  v-else-if="
                                    ['mp3'].includes(
                                      $_.get(item, 'content.files', [])[2].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[2].code
                                    "
                                    type="audio/mpeg"
                                  />
                                  {{ $t("messenger.audio-alert") }}
                                </audio>

                                <img v-else src="@/assets/img/file.png" />

                                <a
                                  v-if="
                                    $_.get(
                                      item,
                                      'content.files',
                                      []
                                    )[2].filename.indexOf('__record__.mp3') !==
                                    -1
                                  "
                                  :href="
                                    CDN_FILE_PREFIX +
                                    $_.get(item, 'content.files', [])[2].code
                                  "
                                >
                                  <img
                                    src="/img/download-grey.svg"
                                    class="img-fluid"
                                    style="width: 20px; margin-left: 10px"
                                    alt=""
                                  />
                                </a>

                                <div
                                  class="layer"
                                  v-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[2].ext
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="
                              $_.size($_.get(item, 'content.files', [])) > 3
                            "
                            class="message-media message-media--4"
                          >
                            <div
                              class="message-media__left"
                              @click="
                                onClickMediaItem(
                                  $_.get(item, 'content.files', []),
                                  0
                                )
                              "
                            >
                              <img
                                v-if="
                                  ['jpeg', 'jpg', 'png'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              />

                              <video
                                v-else-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                />
                                {{ $t("messenger.video-alert") }}
                              </video>

                              <audio
                                v-else-if="
                                  ['mp3'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>

                              <img v-else src="@/assets/img/file.png" />

                              <a
                                v-if="
                                  $_.get(
                                    item,
                                    'content.files',
                                    []
                                  )[0].filename.indexOf('__record__.mp3') !== -1
                                "
                                :href="
                                  CDN_FILE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>

                              <div
                                class="layer"
                                v-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                              />
                            </div>
                            <div class="message-media__right">
                              <div
                                class="message-media__right-top"
                                v-on:click="
                                  onClickMediaItem(
                                    $_.get(item, 'content.files', []),
                                    1
                                  )
                                "
                              >
                                <img
                                  v-if="
                                    ['jpeg', 'jpg', 'png'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  :src="
                                    CDN_IMAGE_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                />

                                <video
                                  v-else-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[1].code
                                    "
                                  />
                                  {{ $t("messenger.video-alert") }}
                                </video>

                                <audio
                                  v-else-if="
                                    ['mp3'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[1].code
                                    "
                                    type="audio/mpeg"
                                  />
                                  {{ $t("messenger.audio-alert") }}
                                </audio>

                                <img v-else src="@/assets/img/file.png" />

                                <a
                                  v-if="
                                    $_.get(
                                      item,
                                      'content.files',
                                      []
                                    )[1].filename.indexOf('__record__.mp3') !==
                                    -1
                                  "
                                  :href="
                                    CDN_FILE_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                >
                                  <img
                                    src="/img/download-grey.svg"
                                    class="img-fluid"
                                    style="width: 20px; margin-left: 10px"
                                    alt=""
                                  />
                                </a>

                                <div
                                  class="layer"
                                  v-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                />
                              </div>
                              <div class="message-media__right-bot">
                                <div
                                  class="message-media__right-bot-left"
                                  v-on:click="
                                    onClickMediaItem(
                                      $_.get(item, 'content.files', []),
                                      2
                                    )
                                  "
                                >
                                  <img
                                    v-if="
                                      ['jpeg', 'jpg', 'png'].includes(
                                        $_.get(item, 'content.files', [])[2].ext
                                      )
                                    "
                                    :src="
                                      CDN_IMAGE_PREFIX +
                                      $_.get(item, 'content.files', [])[2].code
                                    "
                                  />

                                  <video
                                    v-else-if="
                                      ['webm', 'mp4'].includes(
                                        $_.get(item, 'content.files', [])[2].ext
                                      )
                                    "
                                    controls
                                  >
                                    <source
                                      :src="
                                        CDN_VIDEO_PREFIX +
                                        $_.get(item, 'content.files', [])[2]
                                          .code
                                      "
                                    />
                                    {{ $t("messenger.video-alert") }}
                                  </video>

                                  <audio
                                    v-else-if="
                                      ['mp3'].includes(
                                        $_.get(item, 'content.files', [])[2].ext
                                      )
                                    "
                                    controls
                                  >
                                    <source
                                      :src="
                                        CDN_VIDEO_PREFIX +
                                        $_.get(item, 'content.files', [])[2]
                                          .code
                                      "
                                      type="audio/mpeg"
                                    />
                                    {{ $t("messenger.audio-alert") }}
                                  </audio>

                                  <img v-else src="@/assets/img/file.png" />

                                  <a
                                    v-if="
                                      $_.get(
                                        item,
                                        'content.files',
                                        []
                                      )[2].filename.indexOf(
                                        '__record__.mp3'
                                      ) !== -1
                                    "
                                    :href="
                                      CDN_FILE_PREFIX +
                                      $_.get(item, 'content.files', [])[2].code
                                    "
                                  >
                                    <img
                                      src="/img/download-grey.svg"
                                      class="img-fluid"
                                      style="width: 20px; margin-left: 10px"
                                      alt=""
                                    />
                                  </a>

                                  <div
                                    class="layer"
                                    v-if="
                                      ['webm', 'mp4'].includes(
                                        $_.get(item, 'content.files', [])[2].ext
                                      )
                                    "
                                  />
                                </div>
                                <div
                                  class="message-media__right-bot-right"
                                  v-on:click="
                                    onClickMediaItem(
                                      $_.get(item, 'content.files', []),
                                      3
                                    )
                                  "
                                >
                                  <img
                                    v-if="
                                      ['jpeg', 'jpg', 'png'].includes(
                                        $_.get(item, 'content.files', [])[3].ext
                                      )
                                    "
                                    :src="
                                      CDN_IMAGE_PREFIX +
                                      $_.get(item, 'content.files', [])[3].code
                                    "
                                  />

                                  <video
                                    v-else-if="
                                      ['webm', 'mp4'].includes(
                                        $_.get(item, 'content.files', [])[3].ext
                                      )
                                    "
                                    controls
                                  >
                                    <source
                                      :src="
                                        CDN_VIDEO_PREFIX +
                                        $_.get(item, 'content.files', [])[3]
                                          .code
                                      "
                                    />
                                    {{ $t("messenger.video-alert") }}
                                  </video>

                                  <audio
                                    v-else-if="
                                      ['mp3'].includes(
                                        $_.get(item, 'content.files', [])[3].ext
                                      )
                                    "
                                    controls
                                  >
                                    <source
                                      :src="
                                        CDN_VIDEO_PREFIX +
                                        $_.get(item, 'content.files', [])[3]
                                          .code
                                      "
                                      type="audio/mpeg"
                                    />
                                    {{ $t("messenger.audio-alert") }}
                                  </audio>

                                  <img v-else src="@/assets/img/file.png" />

                                  <a
                                    v-if="
                                      $_.get(
                                        item,
                                        'content.files',
                                        []
                                      )[3].filename.indexOf(
                                        '__record__.mp3'
                                      ) !== -1
                                    "
                                    :href="
                                      CDN_FILE_PREFIX +
                                      $_.get(item, 'content.files', [])[3].code
                                    "
                                  >
                                    <img
                                      src="/img/download-grey.svg"
                                      class="img-fluid"
                                      style="width: 20px; margin-left: 10px"
                                      alt=""
                                    />
                                  </a>

                                  <div
                                    class="layer"
                                    v-if="
                                      ['webm', 'mp4'].includes(
                                        $_.get(item, 'content.files', [])[3].ext
                                      )
                                    "
                                  />

                                  <div
                                    v-if="
                                      $_.size(
                                        $_.get(item, 'content.files', [])
                                      ) > 4
                                    "
                                    class="message-media__more-items"
                                  >
                                    +{{
                                      $_.size(
                                        $_.get(item, "content.files", [])
                                      ) - 4
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--jimir3x-->
                          <!-- you -->
                          <div
                            class="replied-content-bubble"
                            v-if="item.content.replied"
                          >
                            <div
                              style="margin-bottom: 10px; padding-top: 5px"
                              v-for="(file, index) in $_.get(
                                item,
                                'content.replied.content.files',
                                []
                              )"
                              :key="index"
                            >
                              <img
                                v-if="['jpeg', 'jpg', 'png'].includes(file.ext)"
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  file.code +
                                  '.jpg?size=100xauto'
                                "
                                width="100"
                              />
                              <video
                                v-else-if="['webm', 'mp4'].includes(file.ext)"
                                width="320"
                                height="240"
                                controls
                              >
                                <source :src="CDN_VIDEO_PREFIX + file.code" />
                                {{ $t("messenger.video-alert") }}
                              </video>
                              <audio
                                v-else-if="['mp3'].includes(file.ext)"
                                controls
                              >
                                <source
                                  :src="CDN_VIDEO_PREFIX + file.code"
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>
                              <img
                                v-else
                                src="@/assets/img/file.png"
                                width="100"
                              />

                              <a
                                v-if="file.filename !== '__record__.mp3'"
                                :href="CDN_FILE_PREFIX + file.code"
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>
                            </div>
                            <span
                              v-html="item.content.replied.content.message"
                            ></span>
                          </div>
                          <div
                            v-for="(ref, index) in $_.get(
                              item,
                              'content.refs',
                              []
                            )"
                            :key="'asdsadsa' + index"
                            class="message-screen-item-detail"
                          >
                            <div v-if="ref.type === 'INQUIRY'" class="item">
                              <div class="item-left">
                                <div class="icon">
                                  <industry-icon
                                    :color="'primary'"
                                    :name="ref.ref.industry.icon"
                                  />
                                </div>
                              </div>
                              <div class="item-right" style="width: 100%">
                                <div class="item-name">
                                  {{ findSubItemByLang(ref.ref.contents).name }}
                                </div>

                                <div class="d-flex mt-3">
                                  <p
                                    class="m-0 mr-2 w-25 refs-grey"
                                    style="width: 10% !important"
                                  >
                                    {{ $t("messenger.unit") }}
                                  </p>
                                  <p class="m-0 w-75">
                                    {{ numberFixer(ref.ref.moq) }}
                                    <span>{{ ref.ref.unitname }}</span>
                                  </p>
                                </div>
                                <div class="d-flex mt-3">
                                  <p
                                    class="m-0 mr-2 w-25 refs-grey"
                                    style="width: 10% !important"
                                  >
                                    {{ $t("messenger.demand") }}
                                  </p>
                                  <p class="m-0 w-75 refs-dark-grey">
                                    {{ ref.ref.demand }}
                                    <span class="ton-color">
                                      / {{ $t("messenger.month") }}</span
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div v-else class="item">
                              <div class="item-left">
                                <div class="icon">
                                  <img
                                    width="88"
                                    height="88"
                                    :src="
                                      getAvatar(
                                        CDN_IMAGE_PREFIX +
                                          $_.first(ref.ref.images),
                                        88
                                      )
                                    "
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div class="item-right">
                                <div class="item-name">
                                  {{ findSubItemByLang(ref.ref.contents).name }}
                                </div>
                                <div class="d-flex align-items-center">
                                  <p class="m-0 mr-2">
                                    {{ $t("messenger.id") }}
                                  </p>
                                  <p class="m-0">
                                    {{ ref.ref.product_id }}
                                  </p>
                                </div>
                                <div class="d-flex mt-3">
                                  <div class="props-item text-dark">
                                    <p class="props-title mb-2">
                                      {{ $t("messenger.ppu") }}
                                    </p>
                                    <span>
                                      {{ ref.ref.currency.symbol }}
                                      {{ ref.ref.min_price }} -
                                      {{ ref.ref.currency.symbol }}
                                      {{ ref.ref.max_price }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="item-content-message"
                            v-html="item.content.message"
                          ></div>
                          <div class="times-message text-right">
                            <span style="font-size: 11px !important">
                              <HRTime :datetime="item.created_at" />
                            </span>
                            <span
                              class="mr-0"
                              style="
                                margin-right: 0 !important;
                                font-size: 12px !important ;
                              "
                            >
                              <!-- {{ item.readed ? "Seen" : "Delivered" }} -->
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="item.from_me" class="messages-screen-item me">
                      <div
                        class="
                          content
                          d-flex
                          w-100
                          align-items-end
                          flex-row-reverse
                        "
                        style="position: relative"
                      >
                        <img
                          :src="
                            getAvatar($_.get(GET_USER_INFO, 'avatar', null), 42)
                          "
                          alt=""
                          width="42"
                          height="42"
                          class="rounded-circle"
                        />
                        <!--jimir3x-->
                        <div
                          class="text-right dropdown-message-icon"
                          style="
                            height: 10px;
                            position: absolute;
                            top: 55px;
                            right: 24px;
                          "
                        >
                          <!--jimir3x-->
                          <!-- right menu message reply delete dropdown -->
                          <b-dropdown
                            right
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            class="
                              icon-answer-message icon-answer-me
                              dropdown-2-a
                            "
                          >
                            <template #button-content class="p-0">
                              <!-- <v-icon :icon="['fas', 'ellipsis-v']"></v-icon>  -->
                              <img
                                src="/img/down-arrow.svg"
                                class="img-fluid ml-2"
                                alt=""
                                style="width: 16px; height: 16px"
                              />
                            </template>
                            <b-dropdown-item
                              href="#"
                              class="pl-0 mr-3"
                              style="padding: 0 important; width: 100%"
                              @click="setReply(item.id)"
                            >
                              <div style="width: 100%" class="">
                                <svg
                                  class="ml-1 mr-3"
                                  style="width: 20px; height: 20px"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M10,9V5L3,12L10,19V14.9C15,14.9 18.5,16.5 21,20C20,15 17,10 10,9Z"
                                  />
                                </svg>

                                <span class="messenger-action-texts">
                                  {{ $t("buttons.reply") }}</span
                                >
                              </div>
                            </b-dropdown-item>
                            <b-dropdown-item
                              class="pl-0 mr-3"
                              style="padding: 0 important; width: 100%"
                              @click="deleteMessage(item.id)"
                            >
                              <div style="width: 100%" class="">
                                <svg
                                  class="ml-1 mr-3"
                                  style="width: 20px; height: 20px"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                  />
                                </svg>

                                <span class="messenger-action-texts">
                                  {{ $t("buttons.delete") }}
                                </span>
                              </div>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                        <!--  -->
                        <div
                          :id="'message-' + item.id"
                          class="
                            chat-message
                            talk-bubble
                            tri-right
                            round
                            right-in
                          "
                        >
                          <!--jimir3x-->
                          <div
                            v-if="
                              $_.size($_.get(item, 'content.files', [])) == 1
                            "
                            class="message-media message-media--1"
                          >
                            <div
                              class="message-media--1__inner"
                              v-on:click="
                                onClickMediaItem(
                                  $_.get(item, 'content.files', []),
                                  0
                                )
                              "
                            >
                              <img
                                v-if="
                                  ['jpeg', 'jpg', 'png'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              />

                              <video
                                v-else-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                />
                                {{ $t("messenger.video-alert") }}
                              </video>

                              <audio
                                v-else-if="
                                  ['mp3'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>

                              <img v-else src="@/assets/img/file.png" />

                              <a
                                v-if="
                                  $_.get(
                                    item,
                                    'content.files',
                                    []
                                  )[0].filename.indexOf('__record__.mp3') !== -1
                                "
                                :href="
                                  CDN_FILE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>

                              <div
                                class="layer"
                                v-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                              />
                            </div>
                          </div>
                          <div
                            v-if="
                              $_.size($_.get(item, 'content.files', [])) == 2
                            "
                            class="message-media message-media--2"
                          >
                            <div
                              class="message-media--2__left"
                              v-on:click="
                                onClickMediaItem(
                                  $_.get(item, 'content.files', []),
                                  0
                                )
                              "
                            >
                              <img
                                v-if="
                                  ['jpeg', 'jpg', 'png'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              />

                              <video
                                v-else-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                />
                                {{ $t("messenger.video-alert") }}
                              </video>

                              <audio
                                v-else-if="
                                  ['mp3'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>

                              <img v-else src="@/assets/img/file.png" />

                              <a
                                v-if="
                                  $_.get(
                                    item,
                                    'content.files',
                                    []
                                  )[0].filename.indexOf('__record__.mp3') !== -1
                                "
                                :href="
                                  CDN_FILE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>

                              <div
                                class="layer"
                                v-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                              />
                            </div>
                            <div
                              class="message-media--2__right"
                              v-on:click="
                                onClickMediaItem(
                                  $_.get(item, 'content.files', []),
                                  1
                                )
                              "
                            >
                              <img
                                v-if="
                                  ['jpeg', 'jpg', 'png'].includes(
                                    $_.get(item, 'content.files', [])[1].ext
                                  )
                                "
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  $_.get(item, 'content.files', [])[1].code
                                "
                              />

                              <video
                                v-else-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[1].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                />
                                {{ $t("messenger.video-alert") }}
                              </video>

                              <audio
                                v-else-if="
                                  ['mp3'].includes(
                                    $_.get(item, 'content.files', [])[1].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>

                              <img v-else src="@/assets/img/file.png" />

                              <a
                                v-if="
                                  $_.get(
                                    item,
                                    'content.files',
                                    []
                                  )[1].filename.indexOf('__record__.mp3') !== -1
                                "
                                :href="
                                  CDN_FILE_PREFIX +
                                  $_.get(item, 'content.files', [])[1].code
                                "
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>

                              <div
                                class="layer"
                                v-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[1].ext
                                  )
                                "
                              />
                            </div>
                          </div>
                          <div
                            v-if="
                              $_.size($_.get(item, 'content.files', [])) == 3
                            "
                            class="message-media message-media--3"
                          >
                            <div
                              class="message-media--3__left"
                              v-on:click="
                                onClickMediaItem(
                                  $_.get(item, 'content.files', []),
                                  0
                                )
                              "
                            >
                              <img
                                v-if="
                                  ['jpeg', 'jpg', 'png'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              />

                              <video
                                v-else-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                />
                                {{ $t("messenger.video-alert") }}
                              </video>

                              <audio
                                v-else-if="
                                  ['mp3'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>

                              <img v-else src="@/assets/img/file.png" />

                              <a
                                v-if="
                                  $_.get(
                                    item,
                                    'content.files',
                                    []
                                  )[0].filename.indexOf('__record__.mp3') !== -1
                                "
                                :href="
                                  CDN_FILE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>

                              <div
                                class="layer"
                                v-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                              />
                            </div>
                            <div class="message-media--3__right">
                              <div
                                class="message-media--3__right-top"
                                v-on:click="
                                  onClickMediaItem(
                                    $_.get(item, 'content.files', []),
                                    1
                                  )
                                "
                              >
                                <img
                                  v-if="
                                    ['jpeg', 'jpg', 'png'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  :src="
                                    CDN_IMAGE_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                />

                                <video
                                  v-else-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[1].code
                                    "
                                  />
                                  {{ $t("messenger.video-alert") }}
                                </video>

                                <audio
                                  v-else-if="
                                    ['mp3'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[1].code
                                    "
                                    type="audio/mpeg"
                                  />
                                  {{ $t("messenger.audio-alert") }}
                                </audio>

                                <img v-else src="@/assets/img/file.png" />

                                <a
                                  v-if="
                                    $_.get(
                                      item,
                                      'content.files',
                                      []
                                    )[1].filename.indexOf('__record__.mp3') !==
                                    -1
                                  "
                                  :href="
                                    CDN_FILE_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                >
                                  <img
                                    src="/img/download-grey.svg"
                                    class="img-fluid"
                                    style="width: 20px; margin-left: 10px"
                                    alt=""
                                  />
                                </a>

                                <div
                                  class="layer"
                                  v-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                />
                              </div>
                              <div
                                class="message-media--3__right-bot"
                                v-on:click="
                                  onClickMediaItem(
                                    $_.get(item, 'content.files', []),
                                    2
                                  )
                                "
                              >
                                <img
                                  v-if="
                                    ['jpeg', 'jpg', 'png'].includes(
                                      $_.get(item, 'content.files', [])[2].ext
                                    )
                                  "
                                  :src="
                                    CDN_IMAGE_PREFIX +
                                    $_.get(item, 'content.files', [])[2].code
                                  "
                                />

                                <video
                                  v-else-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[2].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[2].code
                                    "
                                  />
                                  {{ $t("messenger.video-alert") }}
                                </video>

                                <audio
                                  v-else-if="
                                    ['mp3'].includes(
                                      $_.get(item, 'content.files', [])[2].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[2].code
                                    "
                                    type="audio/mpeg"
                                  />
                                  {{ $t("messenger.audio-alert") }}
                                </audio>

                                <img v-else src="@/assets/img/file.png" />

                                <a
                                  v-if="
                                    $_.get(
                                      item,
                                      'content.files',
                                      []
                                    )[2].filename.indexOf('__record__.mp3') !==
                                    -1
                                  "
                                  :href="
                                    CDN_FILE_PREFIX +
                                    $_.get(item, 'content.files', [])[2].code
                                  "
                                >
                                  <img
                                    src="/img/download-grey.svg"
                                    class="img-fluid"
                                    style="width: 20px; margin-left: 10px"
                                    alt=""
                                  />
                                </a>

                                <div
                                  class="layer"
                                  v-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[2].ext
                                    )
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="
                              $_.size($_.get(item, 'content.files', [])) > 3
                            "
                            class="message-media message-media--4"
                          >
                            <div
                              class="message-media__left"
                              v-on:click="
                                onClickMediaItem(
                                  $_.get(item, 'content.files', []),
                                  0
                                )
                              "
                            >
                              <img
                                v-if="
                                  ['jpeg', 'jpg', 'png'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              />

                              <video
                                v-else-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                />
                                {{ $t("messenger.video-alert") }}
                              </video>

                              <audio
                                v-else-if="
                                  ['mp3'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                                controls
                              >
                                <source
                                  :src="
                                    CDN_VIDEO_PREFIX +
                                    $_.get(item, 'content.files', [])[0].code
                                  "
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>

                              <img v-else src="@/assets/img/file.png" />

                              <a
                                v-if="
                                  $_.get(
                                    item,
                                    'content.files',
                                    []
                                  )[0].filename.indexOf('__record__.mp3') !== -1
                                "
                                :href="
                                  CDN_FILE_PREFIX +
                                  $_.get(item, 'content.files', [])[0].code
                                "
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>

                              <div
                                class="layer"
                                v-if="
                                  ['webm', 'mp4'].includes(
                                    $_.get(item, 'content.files', [])[0].ext
                                  )
                                "
                              />
                            </div>
                            <div class="message-media__right">
                              <div
                                class="message-media__right-top"
                                v-on:click="
                                  onClickMediaItem(
                                    $_.get(item, 'content.files', []),
                                    1
                                  )
                                "
                              >
                                <img
                                  v-if="
                                    ['jpeg', 'jpg', 'png'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  :src="
                                    CDN_IMAGE_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                />

                                <video
                                  v-else-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[1].code
                                    "
                                  />
                                  {{ $t("messenger.video-alert") }}
                                </video>

                                <audio
                                  v-else-if="
                                    ['mp3'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                  controls
                                >
                                  <source
                                    :src="
                                      CDN_VIDEO_PREFIX +
                                      $_.get(item, 'content.files', [])[1].code
                                    "
                                    type="audio/mpeg"
                                  />
                                  {{ $t("messenger.audio-alert") }}
                                </audio>

                                <img v-else src="@/assets/img/file.png" />

                                <a
                                  v-if="
                                    $_.get(
                                      item,
                                      'content.files',
                                      []
                                    )[1].filename.indexOf('__record__.mp3') !==
                                    -1
                                  "
                                  :href="
                                    CDN_FILE_PREFIX +
                                    $_.get(item, 'content.files', [])[1].code
                                  "
                                >
                                  <img
                                    src="/img/download-grey.svg"
                                    class="img-fluid"
                                    style="width: 20px; margin-left: 10px"
                                    alt=""
                                  />
                                </a>

                                <div
                                  class="layer"
                                  v-if="
                                    ['webm', 'mp4'].includes(
                                      $_.get(item, 'content.files', [])[1].ext
                                    )
                                  "
                                />
                              </div>
                              <div class="message-media__right-bot">
                                <div
                                  class="message-media__right-bot-left"
                                  v-on:click="
                                    onClickMediaItem(
                                      $_.get(item, 'content.files', []),
                                      2
                                    )
                                  "
                                >
                                  <img
                                    v-if="
                                      ['jpeg', 'jpg', 'png'].includes(
                                        $_.get(item, 'content.files', [])[2].ext
                                      )
                                    "
                                    :src="
                                      CDN_IMAGE_PREFIX +
                                      $_.get(item, 'content.files', [])[2].code
                                    "
                                  />

                                  <video
                                    v-else-if="
                                      ['webm', 'mp4'].includes(
                                        $_.get(item, 'content.files', [])[2].ext
                                      )
                                    "
                                    controls
                                  >
                                    <source
                                      :src="
                                        CDN_VIDEO_PREFIX +
                                        $_.get(item, 'content.files', [])[2]
                                          .code
                                      "
                                    />
                                    {{ $t("messenger.video-alert") }}
                                  </video>

                                  <audio
                                    v-else-if="
                                      ['mp3'].includes(
                                        $_.get(item, 'content.files', [])[2].ext
                                      )
                                    "
                                    controls
                                  >
                                    <source
                                      :src="
                                        CDN_VIDEO_PREFIX +
                                        $_.get(item, 'content.files', [])[2]
                                          .code
                                      "
                                      type="audio/mpeg"
                                    />
                                    {{ $t("messenger.audio-alert") }}
                                  </audio>

                                  <img v-else src="@/assets/img/file.png" />

                                  <a
                                    v-if="
                                      $_.get(
                                        item,
                                        'content.files',
                                        []
                                      )[2].filename.indexOf(
                                        '__record__.mp3'
                                      ) !== -1
                                    "
                                    :href="
                                      CDN_FILE_PREFIX +
                                      $_.get(item, 'content.files', [])[2].code
                                    "
                                  >
                                    <img
                                      src="/img/download-grey.svg"
                                      class="img-fluid"
                                      style="width: 20px; margin-left: 10px"
                                      alt=""
                                    />
                                  </a>

                                  <div
                                    class="layer"
                                    v-if="
                                      ['webm', 'mp4'].includes(
                                        $_.get(item, 'content.files', [])[2].ext
                                      )
                                    "
                                  />
                                </div>
                                <div
                                  class="message-media__right-bot-right"
                                  v-on:click="
                                    onClickMediaItem(
                                      $_.get(item, 'content.files', []),
                                      3
                                    )
                                  "
                                >
                                  <img
                                    v-if="
                                      ['jpeg', 'jpg', 'png'].includes(
                                        $_.get(item, 'content.files', [])[3].ext
                                      )
                                    "
                                    :src="
                                      CDN_IMAGE_PREFIX +
                                      $_.get(item, 'content.files', [])[3].code
                                    "
                                  />

                                  <video
                                    v-else-if="
                                      ['webm', 'mp4'].includes(
                                        $_.get(item, 'content.files', [])[3].ext
                                      )
                                    "
                                    controls
                                  >
                                    <source
                                      :src="
                                        CDN_VIDEO_PREFIX +
                                        $_.get(item, 'content.files', [])[3]
                                          .code
                                      "
                                    />
                                    {{ $t("messenger.video-alert") }}
                                  </video>

                                  <audio
                                    v-else-if="
                                      ['mp3'].includes(
                                        $_.get(item, 'content.files', [])[3].ext
                                      )
                                    "
                                    controls
                                  >
                                    <source
                                      :src="
                                        CDN_VIDEO_PREFIX +
                                        $_.get(item, 'content.files', [])[3]
                                          .code
                                      "
                                      type="audio/mpeg"
                                    />
                                    {{ $t("messenger.audio-alert") }}
                                  </audio>

                                  <img v-else src="@/assets/img/file.png" />

                                  <a
                                    v-if="
                                      $_.get(
                                        item,
                                        'content.files',
                                        []
                                      )[3].filename.indexOf(
                                        '__record__.mp3'
                                      ) !== -1
                                    "
                                    :href="
                                      CDN_FILE_PREFIX +
                                      $_.get(item, 'content.files', [])[3].code
                                    "
                                  >
                                    <img
                                      src="/img/download-grey.svg"
                                      class="img-fluid"
                                      style="width: 20px; margin-left: 10px"
                                      alt=""
                                    />
                                  </a>

                                  <div
                                    class="layer"
                                    v-if="
                                      ['webm', 'mp4'].includes(
                                        $_.get(item, 'content.files', [])[3].ext
                                      )
                                    "
                                  />
                                  <div
                                    v-if="
                                      $_.size(
                                        $_.get(item, 'content.files', [])
                                      ) > 4
                                    "
                                    class="message-media__more-items"
                                  >
                                    +{{
                                      $_.size(
                                        $_.get(item, "content.files", [])
                                      ) - 4
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--jimir3x-->
                          <!-- me -->

                          <div
                            class="replied-content-bubble"
                            v-if="item.content.replied"
                          >
                            <div
                              style="margin-bottom: 10px; padding-top: 5px"
                              v-for="(file, index) in $_.get(
                                item,
                                'content.replied.content.files',
                                []
                              )"
                              :key="index"
                            >
                              <img
                                v-if="['jpeg', 'jpg', 'png'].includes(file.ext)"
                                :src="
                                  CDN_IMAGE_PREFIX +
                                  file.code +
                                  '.jpg?size=100xauto'
                                "
                                width="100"
                              />
                              <video
                                v-else-if="['webm', 'mp4'].includes(file.ext)"
                                width="320"
                                height="240"
                                controls
                              >
                                <source :src="CDN_VIDEO_PREFIX + file.code" />
                                {{ $t("messenger.video-alert") }}
                              </video>
                              <audio
                                v-else-if="['mp3'].includes(file.ext)"
                                controls
                              >
                                <source
                                  :src="CDN_VIDEO_PREFIX + file.code"
                                  type="audio/mpeg"
                                />
                                {{ $t("messenger.audio-alert") }}
                              </audio>
                              <img
                                v-else
                                src="@/assets/img/file.png"
                                width="100"
                              />

                              <a
                                v-if="file.filename !== '__record__.mp3'"
                                :href="CDN_FILE_PREFIX + file.code"
                              >
                                <img
                                  src="/img/download-grey.svg"
                                  class="img-fluid"
                                  style="width: 20px; margin-left: 10px"
                                  alt=""
                                />
                              </a>
                            </div>

                            <span v-html="item.content.replied.content.message">
                            </span>
                          </div>
                          <!--{{ $_.get($_.first(item.content.refs), "type") }} -->
                          <div
                            v-for="(ref, index) in $_.get(
                              item,
                              'content.refs',
                              []
                            )"
                            :key="'asdsadsa' + index"
                            class="message-screen-item-detail"
                          >
                            <div v-if="ref.type === 'INQUIRY'" class="item">
                              <div class="item-left">
                                <div class="icon">
                                  <industry-icon
                                    :color="'primary'"
                                    :name="ref.ref.industry.icon"
                                  />
                                </div>
                              </div>
                              <div class="item-right">
                                <p class="industry-name">
                                  {{ ref.ref.industry.name }}
                                </p>
                                <div class="item-name">
                                  {{ findSubItemByLang(ref.ref.contents).name }}
                                </div>
                                <div class="d-flex mt-1">
                                  <div class="props-item">
                                    <p class="props-title">
                                      {{ $t("messenger.unit") }}
                                    </p>
                                    <span class="text-capitalize">{{
                                      ref.ref.unit.shortname
                                    }}</span>
                                  </div>
                                  <div class="props-item">
                                    <p class="props-title">
                                      {{ $t("messenger.demand") }}
                                    </p>
                                    <span>{{ ref.ref.demand }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-else class="">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="item-name">
                                    <h4
                                      style="
                                        margin-bottom: 10px;
                                        font-size: 16px !important;
                                        text-transform: capitalize;
                                      "
                                    >
                                      {{
                                        findSubItemByLang(ref.ref.contents).name
                                      }}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="item-left">
                                    <div class="icon">
                                      <img
                                        width="88"
                                        height="88"
                                        style="border-radius: 6px"
                                        :src="
                                          getAvatar(
                                            CDN_IMAGE_PREFIX +
                                              $_.first(ref.ref.images),
                                            88
                                          )
                                        "
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-8">
                                  <div class="item-right">
                                    <div class="d-flex align-items-center">
                                      <p class="m-0 mr-2 w-25 refs-grey">
                                        {{ $t("messenger.price") }}
                                      </p>
                                      <p class="m-0 w-75 refs-dark-grey">
                                        {{ ref.ref.currency.symbol }}
                                        {{ ref.ref.min_price }} -

                                        {{ ref.ref.max_price }} /
                                        <span>{{
                                          ref.ref.unit.shortname
                                        }}</span>
                                      </p>
                                    </div>
                                    <div class="d-flex mt-3">
                                      <p class="m-0 mr-2 w-25 refs-grey">
                                        {{ $t("messenger.moq") }}
                                      </p>
                                      <p class="m-0 w-75 refs-dark-grey">
                                        {{ numberFixer(ref.ref.moq) }}
                                        <span>{{
                                          ref.ref.unit.shortname
                                        }}</span>
                                      </p>
                                    </div>
                                    <div class="d-flex mt-3">
                                      <p class="m-0 mr-2 w-25 refs-grey">
                                        {{ $t("messenger.supply") }}
                                      </p>
                                      <p class="m-0 w-75 refs-dark-grey">
                                        {{ numberFixer(ref.ref.stock) }} /
                                        <span>{{
                                          ref.ref.unit.shortname
                                        }}</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-html="item.content.message"
                            style="
                              margin-top: 5px;
                              margin-bottom: 5px;
                              font-size: 12px;
                              color: #6d757e;
                              font-size: 15px;
                            "
                            class="content-message-text-size"
                          ></div>
                          <div class="times-message text-right">
                            <span style="font-size: 11px !important">
                              <HRTime :datetime="item.created_at" />
                            </span>
                            <span
                              class="mr-0"
                              style="
                                margin-right: 0 !important;
                                font-size: 12px !important;
                              "
                            >
                              {{ item.readed ? "Seen" : "Delivered" }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="hr-message-box" />
                </div>
              </div>

              <!-- MESSAGE SECTION -->

              <div class="new-message-input">
                <div class="send-box">
                  <div
                    v-if="$_.size(filePreviews) > 0"
                    class="sending-file-div d-flex row"
                  >
                    <div
                      v-for="(file, index) in filePreviews"
                      :key="index"
                      class="uploading-item-mes"
                    >
                      <div class="upload-item-mes-wrapper">
                        <audio
                          class="sending-file-audio-mes"
                          v-if="['audio/mpeg'].includes(file.type)"
                          controls
                        >
                          <source :src="file.preview" type="audio/mpeg" />
                          {{ $t("messenger.audio-alert") }}
                        </audio>
                        <img
                          class="sending-file-img-mes"
                          v-else
                          :src="file.preview"
                          width="50xauto"
                        />
                      </div>
                      <p
                        v-if="file.name !== '__record__.mp3'"
                        class="sending-file-name-mes"
                      >
                        {{ file.name }}
                      </p>

                      <a @click="deleteFile(index)">
                        <img
                          src="/img/cross.svg"
                          alt=""
                          class="cross-delete-mssenger"
                          width="18"
                        />
                      </a>
                    </div>
                  </div>

                  <div
                    class="replying-message-box"
                    v-if="replied.content != null"
                  >
                    <div
                      class="reply-inbox"
                      v-if="replied.content != null"
                      v-html="replied.content.message"
                    ></div>
                  </div>

                  <div
                    v-if="$_.has(talkAbout, 'id')"
                    class="
                      message-screen-item-detail-inner
                      d-flex
                      align-items-end
                      w-100
                    "
                  >
                    <div v-if="talkAbout.type === 'INQUIRY'" class="item">
                      <div class="item-left">
                        <div class="icon">
                          <industry-icon
                            :color="'primary'"
                            :name="talkAbout.industry.icon"
                          />
                        </div>
                      </div>
                      <div class="item-right" style="width: 100%">
                        <div class="item-name">
                          {{ findSubItemByLang(talkAbout.contents).name }}
                        </div>

                        <div class="d-flex mt-3">
                          <p
                            class="m-0 mr-2 w-25 refs-grey"
                            style="width: 10% !important"
                          >
                            {{ $t("messenger.unit") }}
                          </p>
                          <p class="m-0 w-75">
                            {{ talkAbout.moq }}
                            <span>{{ talkAbout.unit.shortname }}</span>
                          </p>
                        </div>
                        <div class="d-flex mt-3">
                          <p
                            class="m-0 mr-2 w-25 refs-grey"
                            style="width: 10% !important"
                          >
                            {{ $t("messenger.demand") }}
                          </p>
                          <p class="m-0 w-75 refs-dark-grey">
                            {{ talkAbout.demand }}
                            <span class="ton-color">
                              / {{ $t("messenger.month") }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-else class="item">
                      <div class="item-left">
                        <div>
                          <img
                            :src="
                              getAvatar(
                                CDN_IMAGE_PREFIX + $_.first(talkAbout.images),
                                88
                              )
                            "
                            width="88"
                            height="88"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="item-right">
                        <div class="item-name" style="margin-top: 10px">
                          {{ findSubItemByLang(talkAbout.contents).name }}
                        </div>
                        <!-- <div class="d-flex align-items-center">
                                <p class="m-0 mr-2">{{ $t('messenger.id') }}</p>
                                <p class="m-0">
                                    {{ talkAbout.product_id }}
                                </p>
                            </div> -->
                        <div class="d-flex mt-3">
                          <div class="props-item text-dark">
                            <p class="props-title mb-2">
                              {{ $t("messenger.ppu") }}
                            </p>
                            <span>
                              {{ talkAbout.currency.symbol }}
                              {{ talkAbout.min_price }} -
                              {{ talkAbout.currency.symbol }}
                              {{ talkAbout.max_price }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="new-message-input-text-box">
              </div> -->
                <div
                  class="d-flex justify-content-between align-items-center px-3"
                  style="height: 55px"
                >
                  <div
                    class="icon mr-3 d-flex messenger-voice-icons"
                    @click="!$_.has(talkAbout, 'type') ? openRecorder() : null"
                  >
                    <label class="myLabel icon" v-if="recorder.recording">
                      <span class="cursor-pointer">
                        <a id="play-video" class="video-play-button" href="#">
                          <img
                            src="/img/microphone.svg"
                            style="
                              width: 15px !important;
                              height: 15px !important;
                              margin-top: 15px;
                              margin-left: 4px;
                            "
                            alt=""
                            class=""
                          />
                        </a>
                      </span>
                    </label>
                    <label class="myLabel icon" v-else>
                      <span class="cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          width="24"
                          height="24"
                          viewBox="0 0 512 512"
                          class="vac-icon-messenger-blue"
                        >
                          <path
                            id="vac-icon-microphone"
                            d="M432.8,216.4v39.2c0,45.2-15.3,84.3-45.2,118.4c-29.8,33.2-67.3,52.8-111.6,57.9v40.9h78.4c5.1,0,10.2,1.7,13.6,6c4.3,4.3,6,8.5,6,13.6c0,5.1-1.7,10.2-6,13.6c-4.3,4.3-8.5,6-13.6,6H157.6c-5.1,0-10.2-1.7-13.6-6c-4.3-4.3-6-8.5-6-13.6c0-5.1,1.7-10.2,6-13.6c4.3-4.3,8.5-6,13.6-6H236v-40.9c-44.3-5.1-81.8-23.9-111.6-57.9s-45.2-73.3-45.2-118.4v-39.2c0-5.1,1.7-10.2,6-13.6c4.3-4.3,8.5-6,13.6-6s10.2,1.7,13.6,6c4.3,4.3,6,8.5,6,13.6v39.2c0,37.5,13.6,70.7,40,97.1s59.6,40,97.1,40s70.7-13.6,97.1-40c26.4-26.4,40-59.6,40-97.1v-39.2c0-5.1,1.7-10.2,6-13.6c4.3-4.3,8.5-6,13.6-6c5.1,0,10.2,1.7,13.6,6C430.2,206.2,432.8,211.3,432.8,216.4z M353.5,98v157.6c0,27.3-9.4,50.3-29,69c-19.6,19.6-42.6,29-69,29s-50.3-9.4-69-29c-19.6-19.6-29-42.6-29-69V98c0-27.3,9.4-50.3,29-69c19.6-19.6,42.6-29,69-29s50.3,9.4,69,29C344.2,47.7,353.5,71.6,353.5,98z"
                          ></path>
                        </svg>
                      </span>
                    </label>
                    <!--<label class="myLabel icon ml-5" v-if="recorder.status">
                    <a @click="clickStartRecording()" style="width: 30px">
                      <img
                        style="width: 30px"
                        src="/img/record.svg"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                  </label> -->
                    <label
                      class="myLabel icon ml-5"
                      v-if="recorder.status"
                      style="
                        margin-top: 5px !important;
                        margin-bottom: 0 !important;
                      "
                    >
                      <a @click="stopRecord()" style="width: 30px">
                        <img
                          :src="pictureHover"
                          @mouseover="hover = true"
                          @mouseleave="hover = false"
                          alt=""
                          style="width: 33px"
                          class="img-fluid"
                        />
                      </a>
                    </label>
                    <label
                      class="myLabel icon ml-5"
                      v-if="recorder.status"
                      style="
                        margin-top: 8px !important;
                        margin-bottom: 0 !important;
                      "
                    >
                      <p
                        style="
                          font-size: 30px;
                          font-weight: 300;
                          margin-top: 2px;
                          color: #a7a7a7;
                          font-family: 'Exo', sans-serif;
                        "
                      >
                        {{ formatTime(recorder.time) }}
                      </p>
                    </label>
                  </div>

                  <vue-editor
                    v-show="!recorder.status"
                    v-model="chatMessage"
                    class="messenger-text-input"
                    :editorOptions="editorOptions"
                    ref="editor"
                  />

                  <div
                    class="icon ml-3"
                    @click="openSocialAccountsModal()"
                    v-if="!recorder.status"
                  >
                    <label class="myLabel icon">
                      <span class="cursor-pointer">
                        <img
                          src="/img/zoom.svg"
                          class="img-fluid"
                          style="width: 40px"
                          alt=""
                        />
                      </span>
                    </label>
                  </div>
                  <div class="icon ml-3" v-if="!recorder.status">
                    <label class="myLabel icon">
                      <b-form-file
                        id="file"
                        class="inputfile"
                        accept=".pdf, .doc, .docx, .xls, .zip, .rar, .jpeg, .jpg, .png, .mp4, .webm, .mp3"
                        v-model="files"
                        multiple
                        :disabled="$_.has(talkAbout, 'type')"
                      >
                      </b-form-file>
                      <span class="cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="vac-icon-messenger-blue"
                        >
                          <path
                            id="vac-icon-paperclip"
                            d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z"
                          ></path>
                        </svg>
                      </span>
                    </label>
                  </div>
                  <div
                    class="icon ml-3 position-relative"
                    v-if="!recorder.status"
                  >
                    <div
                      @click="closeEmoji"
                      v-if="semoji"
                      class="emoji-bg"
                    ></div>
                    <div class="emoji-picker">
                      <VEmojiPicker
                        size="48"
                        @select="selectEmoji"
                        v-show="semoji"
                      />
                    </div>
                    <svg
                      @click="toggleEmoji"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      class="vac-icon-messenger-blue"
                      style="margin-top: -9px !important"
                    >
                      <path
                        id="vac-icon-emoji"
                        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                      ></path>
                    </svg>
                  </div>
                  <div class="icon ml-3" @click="send" v-if="!recorder.status">
                    <label class="myLabel icon">
                      <span class="cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="vac-icon-messenger-blue"
                        >
                          <path
                            id="vac-icon-send"
                            d="M2,21L23,12L2,3V10L17,12L2,14V21Z"
                          ></path>
                        </svg>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <b-progress
                v-if="percentages.files !== null"
                :value="percentages.files"
                :max="100"
                variant="primary"
                show-progress
                animated
                class="mb-3 progress-bar-messages"
              >
              </b-progress>
              <div class="div-bar"></div>
            </div>
            <div class="col-3 mes-rigth-section" :style="{ height: isSafari ? '50px': false }">
              <div class="messages-person-info">
                <div
                  class="
                    messages-person-info-header
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <button
                    v-if="!hideDetails"
                    @click="hideDetails = !hideDetails"
                    class="btn btn-outline-primary btn-sm"
                    style="
                      padding: 1px 10px;
                      font-size: 15px;
                      border: 1px solid;
                    "
                  >
                    <svg style="width: 20px; height: 20px" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z"
                      />
                    </svg>
                    <!-- {{ $t("messenger.hide-d") }} -->
                  </button>
                </div>
              </div>

              <div class="row" v-if="$_.has(sconv, 'to')">
                <div class="col-md-12">
                  <div v-if="!hideDetails" class="message-right-section-tabs">
                    <b-tabs content-class="" fill style="background: #f8f8f8">
                      <b-tab :title="$t('messenger.about')" active>
                        <div
                          class="card-info-mes card-list-item-collapsed-content"
                        >
                          <div class="user-messages-detail">
                            <div class="d-flex align-items-center">
                              <img
                                :src="getAvatar(sconv.to.avatar, 50)"
                                alt=""
                                width="50"
                                height="50"
                                class="rounded-circle user-img"
                              />
                              <div>
                                <a
                                  href=""
                                  class="user-detail-name"
                                  @click.prevent="
                                    getUserProfile(sconv.to.uid)
                                  "
                                  >{{ sconv.to.name }} {{ sconv.to.surname }}

                                  <img
                                    v-if="$_.get(sconv, 'to.verification.icon')"
                                    :src="$_.get(sconv, 'to.verification.icon')"
                                    class="img-fluid ml-3"
                                    alt=""
                                  />
                                </a>
                                <span class="user-detail-title">{{
                                  sconv.to.title.name
                                }}</span>
                              </div>
                            </div>
                            <div
                              class="
                                user-detail-linked
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <div class="user-detail-address">
                                <img
                                  src="/img/map-icon-card-view.svg"
                                  class="img-fluid"
                                  alt=""
                                  style="margin-right: 10px"
                                />
                                <span>
                                  {{
                                    getDefault(sconv.to.locations).address.city || 'unnamed'
                                  }}
                                  -
                                  {{
                                    getDefault(sconv.to.locations).address
                                      .country
                                  }}
                                </span>
                              </div>

                              <div class="social-links" style="">
                                <b-row>
                                  <b-col>
                                    <div
                                      class="user-detail-linked-icons"
                                      v-if="userSocialLinks['LINKEDIN']"
                                    >
                                      <a
                                        :href="
                                          userSocialLinks['LINKEDIN']['link']
                                        "
                                        target="_blank"
                                      >
                                        <img
                                          src="/img/card-linkedin-map.svg"
                                          class="img-fluid social-icon"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div
                                      class="user-detail-linked-icons"
                                      v-else
                                    >
                                      <img
                                        src="/img/card-linkedin-map.svg"
                                        class="img-fluid social-icon-disabled"
                                        alt=""
                                      />
                                    </div>
                                  </b-col>
                                  <b-col>
                                    <div
                                      class="user-detail-linked-icons"
                                      v-if="userSocialLinks['FACEBOOK']"
                                    >
                                      <a
                                        :href="
                                          userSocialLinks['FACEBOOK']['link']
                                        "
                                        target="_blank"
                                      >
                                        <img
                                          src="/img/facebook-icon.svg"
                                          class="img-fluid social-icon"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div
                                      class="user-detail-linked-icons"
                                      v-else
                                    >
                                      <img
                                        src="/img/facebook-icon.svg"
                                        class="img-fluid social-icon-disabled"
                                        size="50"
                                        alt="facebook"
                                      />
                                    </div>
                                  </b-col>
                                  <b-col>
                                    <div
                                      class="user-detail-linked-icons"
                                      v-if="userSocialLinks['TWITTER']"
                                    >
                                      <a
                                        :href="
                                          userSocialLinks['TWITTER']['link']
                                        "
                                        target="_blank"
                                      >
                                        <img
                                          src="/img/twitter-icon.svg"
                                          class="img-fluid social-icon"
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div
                                      class="user-detail-linked-icons"
                                      v-else
                                    >
                                      <img
                                        src="/img/twitter-icon.svg"
                                        class="img-fluid social-icon-disabled"
                                        alt=""
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                            <hr />
                            <div class="user-detail-props">
                              <p class="title">
                                {{ $t("messenger.userindustry") }}
                              </p>
                              <p class="value">{{ sconv.to.industry.name }}</p>
                            </div>
                            <div class="user-detail-props">
                              <p class="title">{{ $t("messenger.company") }}</p>
                              <p class="value">{{ sconv.to.company.name }}</p>
                            </div>
                            <div
                              class="user-detail-props"
                              v-if="sconv.to.position"
                            >
                              <p class="title">{{$t("profile.contents.myprofile.aboutme.position")}}</p>
                              <p class="value">{{ sconv.to.position.name }}</p>
                            </div>
                            <div class="user-detail-props">
                              <p class="title">
                                {{ $t("messenger.experience") }}
                              </p>
                              <p class="value">
                                {{
                                  sconv.to.years_of_experience.high === "+"
                                    ? sconv.to.years_of_experience.low + "+"
                                    : sconv.to.years_of_experience.low +
                                      "-" +
                                      sconv.to.years_of_experience.high
                                }}
                              </p>
                            </div>
                            <div class="user-detail-props">
                              <p class="title">{{ $t("messenger.about") }}</p>
                              <nl2br
                                tag="p"
                                className="value"
                                :text="sconv.to.about"
                              />
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <b-tab :title="$t('home.bar.requests')">
                        <div v-if="$_.has(inquiry, 'id')">
                          <div
                            class="card-info-mes messenger-request-card"
                            style="padding: 5px"
                          >
                            <div
                              class="buyer-mes-card-head d-flex w-100"
                              style="padding: 25px"
                            >
                              <div class="item-head-info w-100">
                                <h3
                                  class="buyer-mes-card-title text-capitalize"
                                >
                                  {{
                                    this.inquiry.contents.find((x) => x.default)
                                      .name
                                  }}
                                </h3>
                                <nl2br
                                  tag="p"
                                  className="buyer-mes-card-p pr-0"
                                  :text="
                                    findSubItemByLang(inquiry.contents)
                                      .description
                                  "
                                />
                                <div
                                  class="row buyer-map-card-industry-icon"
                                  style="margin-top: 20px"
                                >
                                  <div class="col-md-1 pr-0">
                                    <img
                                      src="/img/industry-icon.svg"
                                      class="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    class="col-md-10 pl-2 text-left"
                                    style="font-size: 20px"
                                  >
                                    <p>{{ inquiry.industry.name }}</p>
                                  </div>
                                </div>

                                <b-row class="align-items-center">
                                  <b-col>
                                    <p class="buyer-mes-card-unit">
                                      {{ $t("messenger.unit") }}
                                    </p>
                                     </b-col>

                                   <b-col>
                                    <p
                                      class="
                                        buyer-mes-card-unit-content
                                        text-capitalize
                                      "
                                    >
                                      {{ inquiry.unit.name }}
                                    </p>
                                  </b-col>
                                </b-row>

                                <b-row class="align-items-center">
                                  <b-col>
                                  <p class="buyer-mes-card-unit">
                                    {{ $t("messenger.demand") }}
                                  </p>
                                  </b-col>
                                   <b-col>
                                  <p
                                    class="
                                      buyer-mes-card-unit-content
                                      text-capitalize
                                    "
                                  >
                                    {{ inquiry.demand }}
                                  </p>
                                     </b-col>
                                </b-row>
                              </div>
                            </div>
                          </div>

                          <div
                            class="
                              card-info-mes
                              messenger-product-bottom-cards-1
                            "
                          >
                            <div v-if="$_.has(inquiry, 'id')">
                              <b-pagination
                                align="center"
                                pills
                                v-if="$_.has(inquiry, 'id')"
                                v-model="inquiryCurrentPage"
                                :total-rows="inquiryTotal"
                                :per-page="inquiryPerPage"
                                aria-controls="my-table"
                                @change="inquiryPageChange()"
                                size="sm"
                              >
                              </b-pagination>
                            </div>
                          </div>

                          <div
                            class="
                              card-info-mes
                              messenger-product-bottom-cards-2
                            "
                          >
                            <div
                              v-if="$_.has(inquiry, 'id')"
                              class="messenger-right-talk-this"
                            >
                              <a
                                href="javascript:void(0)"
                                @click="setTalkAbout(inquiry, 'INQUIRY')"
                              >
                                <h2>
                                  <img
                                    src="/img/left-arrow-chat.svg"
                                    class="img-fluid mr-2"
                                    alt=""
                                    size="50"
                                  />
                                  {{ $t("messenger.talk-r") }}
                                </h2>
                              </a>
                            </div>
                          </div>
                        </div>

                        <div v-else>
                          <div class="row no-qu-re">
                            <div class="col-md-12 text-right">
                              <h1>
                                <span style="text-transform: capitalize">
                                  {{ sconv.to.name }}
                                  {{ sconv.to.surname }}</span
                                >
                                currently has not <br class="" />
                                any requests yet.
                              </h1>
                              <img
                                src="/img/no-requ-quote.svg"
                                class="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </b-tab>

                      <b-tab :title="$t('home.bar.quotes')">
                        <div v-if="$_.has(product, 'id')">
                          <div
                            class="card-info-mes messenger-quotes-card"
                            style="padding: 20px"
                          >
                            <div class="card-body">
                              <div class="card-list-item-head mb-4 d-flex">
                                <div
                                  class="row"
                                  style="width: 570px !important"
                                >
                                  <!-- :src="getAvatar(sconv.to.avatar, 64)" -->
                                  <div class="col-md-12">
                                    <b-carousel
                                      id="carousel-1"
                                      v-model="slide"
                                      :interval="4000"
                                      controls
                                      indicators
                                      background="#ababab"
                                      img-width="1024"
                                      img-height="480"
                                      style="text-shadow: 1px 1px 2px #333"
                                    >
                                      <!-- Text slides with image -->
                                      <b-carousel-slide
                                        v-for="item in product.images"
                                        :key="item.id"
                                        :img-src="CDN_IMAGE_PREFIX + item"
                                      ></b-carousel-slide>
                                    </b-carousel>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="ml-0">
                                      <h3 class="text-capitalize">
                                        {{
                                          this.product.contents.find(
                                            (x) => x.default
                                          ).name
                                        }}
                                      </h3>
                                      <span class="id"
                                        >ID {{ product.product_id }}</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="card-list-item-content">
                                <div class="card-list-item-content-item d-flex">
                                  <p class="w-25 key">
                                    {{ $t("messenger.price") }}
                                  </p>
                                  <p class="w-75 value">
                                {{product.max_price}} -
                                 {{product.min_price }}
                                    <span class="ml-2 text-lowercase">
                                      / {{ product.unit.shortname }}</span
                                    >
                                  </p>
                                </div>
                                <div class="card-list-item-content-item d-flex">
                                  <p class="w-25 key">
                                    {{ $t("messenger.moq") }}
                                  </p>
                                  <p class="w-75 value text-capitalize">
                                    {{ numberFixer(product.moq) }}
                                    <span class="ml-2 text-lowercase">
                                      {{ product.unit.shortname }}</span
                                    >
                                  </p>
                                </div>

                                <div class="card-list-item-content-item d-flex">
                                  <p class="w-25 key">
                                    {{ $t("messenger.supply") }}
                                  </p>
                                  <p class="w-75 value text-lowercase">
                                    {{ numberFixer(product.stock) }}
                                    <span> /{{ $t("messenger.month") }}</span>
                                  </p>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-12">
                                  <p style="font-size: 14px">
                                    <nl2br
                                      tag="p"
                                      className="content-product-right"
                                      :text="
                                        findSubItemByLang(product.contents)
                                          .description
                                      "
                                    />
                                  </p>
                                </div>

                                <div class="col-md-12">
                                  <div class="row buyer-map-card-industry-icon">
                                    <div class="col-md-1 pr-0">
                                      <img
                                        src="/img/industry-icon.svg"
                                        alt=""
                                        class="img-fluid"
                                      />
                                    </div>
                                    <div class="col-md-10 pl-2 text-left"
                                         style="font-size: 20px"
                                    >
                                      <p>{{ product.industry.name }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="
                              card-info-mes
                              messenger-product-bottom-cards-1
                            "
                          >
                            <div v-if="$_.has(product, 'id')">
                              <b-pagination
                                align="center"
                                pills
                                v-if="$_.has(product, 'id')"
                                v-model="productCurrentPage"
                                :total-rows="productTotal"
                                :per-page="productPerPage"
                                aria-controls="my-table"
                                @change="productPageChange()"
                                size="sm"
                                class="mes-pagination"
                              >
                              </b-pagination>
                            </div>
                          </div>

                          <div
                            class="
                              card-info-mes
                              messenger-product-bottom-cards-2
                            "
                          >
                            <div
                              class="card-info-mes messenger-right-talk-this"
                            >
                              <a
                                href="javascript:void()"
                                @click="setTalkAbout(product, 'PRODUCT')"
                              >
                                <h2>
                                  <img
                                    src="/img/left-arrow-chat.svg"
                                    class="img-fluid mr-2"
                                    alt=""
                                    size="50"
                                  />
                                  {{ $t("messenger.talk-q") }}
                                </h2>
                              </a>
                            </div>
                          </div>
                        </div>

                        <div v-else>
                          <div class="row no-qu-re">
                            <div class="col-md-12 text-right">
                              <h1>
                                <span style="text-transform: capitalize">
                                  {{ sconv.to.name }} {{ sconv.to.surname }}
                                </span>
                                currently has not <br class="" />
                                any quotes yet.
                              </h1>
                              <img
                                src="/img/no-requ-quote.svg"
                                class="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-show="$_.has(sconv, '_id') === false || convs.NORMAL.length === 0"
        >
          <!-- <div class="col-md-12">
            <div class="row no-gutters messenger-no-message-screen">
              <div class="col-md-12 text-center">
                <h2>{{ $t("messenger.nochat") }}</h2>
                <p>{{ $t("messenger.nochat-p") }}</p>
                <a href="">{{ $t("messenger.nochat-a") }}</a>
              </div>
              <div class="col-md-12 text-center">
                <img src="/img/no-message.png" class="img-fluid" alt="" />
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <sweet-modal class="modal-x" ref="socialAccountsModal" id="zoomModal">
        <div v-if="getSocialAccount('ZOOM')">
          <h1>{{ $t("messenger.create-vm") }}</h1>
          <hr />
          <div class="row">
            <div class="col-md-6">
              <div class="row mb-3" @click="createMeetingZoom()">
                <div class="col-md-2">
                  <img
                    src="/img/create-meeting.svg"
                    class="img-fluid"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="col-md-10 text-left">
                  <h3>{{ $t("messenger.create-im") }}</h3>
                </div>
              </div>
              <div class="row" @click="laterMeeting()">
                <div class="col-md-2">
                  <img
                    src="/img/calendar.svg"
                    class="img-fluid"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="col-md-10 text-left">
                  <h3>{{ $t("messenger.arrange") }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="meeting.zoomIcon">
            <div class="col-md-12 text-right">
              <img src="/img/zoom-opacity.svg" class="img-fluid mb-5" alt="" />
            </div>
          </div>
          <form
            action=""
            v-if="meeting.laterMeeting"
            style="padding-left: 50px"
          >
            <div class="row mb-3">
              <div class="col-md-12 text-left">
                <h4>{{ $t("messenger.start") }}*</h4>
              </div>
              <div class="col-md-6">
                <datepicker
                  placeholder="Select Date"
                  v-model="meeting.form.start.date"
                  format="yyyy-MM-dd"
                  class="date-picker-input"
                ></datepicker>
              </div>
              <div>
                <vue-timepicker
                  format="HH:mm"
                  style="background: #ffffff !important"
                  v-model="meeting.form.start.time"
                ></vue-timepicker>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12 text-left">
                <h4>{{ $t("messenger.end") }}*</h4>
              </div>
              <div class="col-md-6">
                <datepicker
                  placeholder="Select Date"
                  v-model="meeting.form.end.date"
                  format="yyyy-MM-dd"
                  class="date-picker-input"
                ></datepicker>
              </div>
              <div>
                <vue-timepicker
                  format="HH:mm"
                  style="background: #ffffff !important"
                  v-model="meeting.form.end.time"
                ></vue-timepicker>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-left">
                <h4>{{ $t("messenger.timezone") }}</h4>
                <b-form-select
                  v-model="meeting.form.timezone"
                  :options="meeting.form.timezones"
                  class="hour-picker-input w-100"
                  style="
                    background: #ffffff !important;
                    width: 92% !important;
                    height: 36px !important;
                    margin-bottom: 40px !important;
                  "
                ></b-form-select>
              </div>
            </div>
            <div
              class="row"
              style="padding-right: 45px; margin-bottom: 32px !important"
            >
              <div class="col-md-12 text-right">
                <a class="zoom-cancel" @click="cancelLaterMeeting()">{{
                  $t("buttons.cancel")
                }}</a>
                <a class="zoom-create" @click="createMeetingZoom()">{{
                  $t("buttons.create")
                }}</a>
              </div>
            </div>
          </form>
          <hr />
          <div class="row">
            <div class="col-md-12 text-left">
              <p>
                {{ $t("messenger.loggedin-zoom") }}
                <!--{{ $_.get(getSocialAccount("ZOOM"), "email") }} -->
                john.smith@company.com
                <a href="" class="login-zoom-logout">{{
                  $t("buttons.cancel")
                }}</a>
              </p>
            </div>
          </div>
        </div>

        <div v-else>
          <h1>{{ $t("messenger.create-zoom") }}</h1>
          <hr class="mb-4" />
          <div class="no-zoom-login text-left">
            <p>
              <img src="/img/account-icons.png" class="img-fluid mr-3" alt="" />
              {{ $t("messenger.login-zoom") }}
              <a class="login-zoom-logout" @click="authZoom()">{{
                $t("buttons.login")
              }}</a>
            </p>
          </div>
        </div>
      </sweet-modal>

      <sweet-modal class="modal-x" ref="reportModal" id="reportModal">
        <h1 class="text-left">{{ $t("report-modal.modal-title") }}</h1>
        <hr />
        <div
          style="
            background: #eff2f6;
            border-radius: 3px;
            margin-bottom: 20px;
            padding-right: 14px;
          "
        >
          <div class="row pt-3">
            <div class="col-md-2 pr-0">
              <img src="/img/account-icons.png" class="img-fluid mt-2" alt="" />
            </div>
            <div class="col-md-10 pl-0 text-left">
              <p>
                {{ $t("report-modal.info-text") }}
              </p>
            </div>
          </div>
        </div>
        <h1
          class="text-left mb-2"
          style="font-size: 12px; font-weight: 300; color: #798390"
        ></h1>

        <div class="row">
          <div class="col-md-12 text-left">
            <b-form-group
              :label="$t('report-modal.form-label')"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                style="margin-top: 20px"
                v-model="rtype"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="1"
              >
                <p style="margin-top: 0px">{{ $t("report-modal.p1") }}</p>
              </b-form-radio>
              <b-form-radio
                v-model="rtype"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="2"
              >
                <p>{{ $t("report-modal.p2") }}</p>
              </b-form-radio>
              <b-form-radio
                v-model="rtype"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="3"
              >
                <p>{{ $t("report-modal.p3") }}</p>
              </b-form-radio>
              <b-form-radio
                v-model="rtype"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="4"
              >
                <p>{{ $t("report-modal.p4") }}</p>
              </b-form-radio>

              <b-form-radio
                v-model="rtype"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="4"
              >
                <p>{{ $t("report-modal.p5") }}</p>
              </b-form-radio>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-right">
            <hr style="margin-top: 50x !important; margin-bottom: 10px" />
            <b-button
              style="
                padding: 4px 20px;
                margin-top: 10px;
                font-size: 15px;
                border-radius: 3px;
              "
              variant="primary"
              @click="reportUser()"
            >
              {{ $t("messenger.report") }}
            </b-button>
          </div>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
import getLastMessagesByUser from "@/graphql/messenger/getLastMessagesByUser.gql";
import getLastConversationList from "@/graphql/messenger/getLastConversations.gql";
import conversation from "@/graphql/messenger/messengerConversation.graphql";
import getInquiries from "@/graphql/user/userRequests.graphql";
import getProducts from "@/graphql/user/getProducts.graphql";
import deleteConversation from "@/graphql/messenger/deleteConversation.gql";
import uploadFile from "@/graphql/messenger/upload.gql";
import countUnreads from "@/graphql/messenger/countUnreads.gql";
import addToMyFavorites from "@/graphql/messenger/addToMyFavorites.gql";
import removeFromMyFavorites from "@/graphql/messenger/removeFromMyFavorites.gql";
import deleteMessageGQL from "@/graphql/messenger/deleteMessage.gql";
import socialAccountsList from "@/graphql/messenger/socialAccountsList.gql";
import createMeeting from "@/graphql/messenger/createMeeting.gql";
import HRTime from "@/components/global/HumanReadableTime.vue";
import BLOCK from "@/graphql/user/block.graphql";
import UNBLOCK from "@/graphql/user/unblock.graphql";
import REPORT from "@/graphql/messenger/report.graphql";
import moment from "moment";
import momentTz from "moment-timezone";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import _ from "lodash";
export default {
  name: "messenger",
  components: {
    VEmojiPicker,
    HRTime,
    Datepicker,
    VueTimepicker,
  },

  data() {
    return {
      rtype: 1,
      isSafari: null,
      pictureStatic: "https://app.connectter.com/img/stop.svg",
      pictureGif: "https://app.connectter.com/img/stop-hover.svg",
      hover: false,
      talkAbout: {},
      slide: 0,
      sliding: null,
      inquiryPerPage: 1,
      inquiryCurrentPage: 1,
      inquiryTotal: 0,
      productPerPage: 1,
      productCurrentPage: 1,
      productTotal: 0,
      inquiry: {},
      product: {},
      lastScrollTop: 0,
      meeting: {
        laterMeeting: false,
        zoomIcon: true,
        form: {
          timezone: null,
          timezones: momentTz.tz.names(),
          start: {
            date: null,
            time: "",
          },
          end: {
            date: null,
            time: "",
          },
        },
      },
      value: 45,
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: false,
        },
        formats: [],
      },
      recorder: {
        status: false,
        recorder: null,
        recording: false,
        cancel: false,
        time: 0,
      },
      socialAccounts: [],
      files: [],
      searchDelay: null,
      search: "",
      type: "NORMAL",
      convs: {
        NORMAL: [],
        FAVORITE: [],
      },
      sconv: {},
      messages: [],
      badge: 0,
      hideDetails: false,
      chatMessage: "",
      semoji: false,
      replied: {
        id: null,
        content: null,
      },
      loadings: {
        messages: false,
        convs: false,
      },
      percentages: {
        files: null,
      },
      filters: {
        inquries: {
          limit: 1,
          page: 1,
          filters: {},
        },

        products: {
          limit: 1,
          page: 1,
          filters: {},
        },

        convList: {
          NORMAL: {
            filters: {
              type: null,
            },
            cursor: null,
            limit: 100,
          },
          FAVORITE: {
            filters: {
              type: "FAVORITE",
            },
            cursor: null,
            limit: 100,
          },
        },
        messageList: {
          conversation_id: null,
          cursor: null,
          limit: 10,
        },
        convDetail: {
          _id: null,
        },
      },
      bulbs: [
        {
          id: 0,
          text: "Hello",
          disabled: false,
        },
        {
          id: 1,
          text: "How Are you",
          disabled: false,
        },
        {
          id: 2,
          text: "How Are you",
          disabled: false,
        },
        {
          id: 3,
          text: "Can I get an offer?",
          disabled: false,
        },
      ],
      /*jimir3x*/
      messagesMediaModal: {
        visible: false,
        index: 0,
        content: null,
        media: [],
      },
      /*jimir3x*/
    };
  },
  watch: {
    "$route.params.id"(val) {
      this.onChangeConv(val);
      this.scrollerKey++;
    },

    chatMessage(message) {
      if (message !== "" && this.sconv._id) {
        this.$io.emit("conversation:typing", {
          conversation_id: this.sconv._id,
          typing: true,
        });
      }

      if (message === "" && this.sconv._id) {
        this.$io.emit("conversation:typing", {
          conversation_id: this.sconv._id,
          typing: false,
        });
      }
    },

    badge(value) {
      this.$store.commit("messages/SET_BADGE", value);
    },
  },

  computed: {
    userSocialLinks() {
      if (!_.has(this, "sconv.to")) {
        return {};
      }

      let accounts = _.get(this, "sconv.to.social_accounts");
      let filtered = _.filter(accounts, (sa) => !_.isNil(sa.link));
      return _.keyBy(filtered, "type");
    },
    pictureHover() {
      if (this.hover == true) {
        return this.pictureGif;
      } else {
        return this.pictureStatic;
      }
    },

    getUid() {
      return _.get(this.sconv, "to.uid", "none");
    },
    conversation_id: {
      get: function () {
        return _.get(this.filters, "convDetail._id");
      },
      set: function (_id) {
        this.filters.convDetail._id = _id;
        this.filters.messageList.conversation_id = _id;
      },
    },

    tconvs() {
      return this.convs[this.type];
    },

    filePreviews() {
      if (_.size(this.files) === 0) {
        return [];
      }

      let previews = [];
      for (let file of this.files) {
        switch (file.type) {
          case "image/jpeg":
          case "image/jpg":
          case "image/png":
            previews.push({
              name: file.name,
              preview: URL.createObjectURL(file),
              type: file.type,
            });
            break;
          case "audio/mpeg":
            previews.push({
              name: file.name,
              preview: URL.createObjectURL(file),
              type: file.type,
            });
            break;

          default:
            previews.push({
              name: file.name,
              preview: require("@/assets/img/file.png"),
              type: file.type,
            });
            break;
        }
      }

      return previews;
    },

    condBulbs() {
      if (_.size(this.filePreviews) > 0) {
        return [];
      }

      return this.bulbs;
    },
  },

  methods: {
    /*jimir3x*/
    onClickMediaItem(media, index) {
      let { code, ext, filename } = media[index];
      this.messagesMediaModal = {
        visible: true,
        index,
        content: {
          code,
          ext,
          filename,
        },
        media,
      };
    },

    onCloseMediaModal() {
      this.messagesMediaModal = {
        visible: false,
        index: 0,
        content: null,
        media: [],
      };
    },

    onCloseMediaModalOverlay(e) {
      if (e.target?.id == "messages-media-modal") this.onCloseMediaModal();
      if (e.target?.id == "messages-media-modal__inner")
        this.onCloseMediaModal();
    },

    onMediaModalPrev() {
      const newIndex = this.messagesMediaModal.index - 1;
      const { code, ext, filename } = this.messagesMediaModal.media[newIndex];

      this.messagesMediaModal.index = newIndex;
      this.messagesMediaModal.content = { code, ext, filename };
    },

    onMediaModalNext() {
      const newIndex = this.messagesMediaModal.index + 1;
      const { code, ext, filename } = this.messagesMediaModal.media[newIndex];

      this.messagesMediaModal.index = newIndex;
      this.messagesMediaModal.content = { code, ext, filename };
    },
    /*jimir3x*/

    inquiryPageChange() {
      this.$nextTick((x) => {
        this.filters.inquries.page = this.inquiryCurrentPage;
        this.getInquiriesRight();
      });
    },
    productPageChange() {
      this.$nextTick((x) => {
        this.filters.products.page = this.productCurrentPage;
        this.getRequestRight();
      });
    },
    async getInquiriesRight() {
      try {
        if (this.getUid == "none") {
          return false;
        }
        this.filters.inquries.filters.uid = this.getUid;

        this.loading = true;
        let data = await this.$apollo.query(
          getInquiries,
          this.filters.inquries
        );

        let inquries = data("**.list");
        this.inquiryTotal = data("**.total");

        this.inquiry = _.first(inquries);
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    async getRequestRight() {
      try {
        if (this.getUid == "none") {
          return false;
        }

        this.filters.products.filters.uid = this.getUid;

        this.loading = true;
        let data = await this.$apollo.query(getProducts, this.filters.products);

        let products = data("**.list");
        this.productTotal = data("**.total");

        this.product = _.first(products);
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    cancelLaterMeeting() {
      this.meeting.zoomIcon = true;
      this.meeting.laterMeeting = false;
    },
    laterMeeting() {
      this.meeting.zoomIcon = false;
      this.meeting.laterMeeting = true;
    },
    formatTime(seconds) {
      let h = Math.floor(seconds / 3600);
      let m = Math.floor((seconds % 3600) / 60);
      let s = Math.round(seconds % 60);
      let returning = [
        h,
        m > 9 ? m : h ? "0" + m : m || "0",
        s > 9 ? s : "0" + s,
      ];

      return _.join(_.filter(returning, Boolean), ":");
    },
    async startRecord() {
      let tid = null;
      try {
        tid = setInterval(() => this.recorder.time++, 1000);
        let chunks = [];
        let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.recorder.recorder = new MediaRecorder(stream);
        this.recorder.recorder.start();
        this.recorder.recorder.addEventListener("dataavailable", (event) =>
          chunks.push(event.data)
        );
        await new Promise((resolve) =>
          this.recorder.recorder.addEventListener("stop", resolve)
        );

        let blob = new Blob(chunks, { type: "audio/mpeg" });
        let file = new File([blob], "__record__.mp3", { type: blob.type });
        this.files.push(file);
        _.forEach(stream.getTracks(), (track) => track.stop());
      } catch (error) {
        return this.$bvToast.toast(this.$t("Toaster.not-recorded"), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        clearInterval(tid);
        this.recorder.time = 0;
        this.recorder.recorder = null;
        this.recorder.status = false;
        this.recorder.recording = false;
      }
    },
    clickStartRecording() {
      if (this.recorder.recording) {
        return;
      }

      this.recorder.recording = true;
      this.startRecord();
    },
    openRecorder() {
      this.recorder.status = true;
      this.clickStartRecording();
    },
    stopRecord() {
      setImmediate(() =>
        this.$nextTick(() => {
          this.recorder.status = false;
          if (this.recorder.recorder !== null) {
            this.recorder.recorder.stop();
          }
        })
      );
    },
    async onChangeConv(val) {
      this.$set(this, "sconv", {});
      this.$set(this, "messages", []);
      this.conversation_id = null;
      this.loadings.messages = true;
      this.lastIndex = 0;
      if (val) {
        this.filters.messageList.cursor = null;
        this.conversation_id = val;
        await this.fetchConvDetail();

        this.type =
          this.type === "FAVORITE" && this.sconv.favorite === true
            ? this.type
            : "NORMAL";

        if (this.search === "" || this.search === null) {
          await this.fetchMessages();
          await this.$nextTick();
          this.scrollBottom();
        }

        if (this.search !== "" && this.search !== null) {
          let index = _.findIndex(this.convs[this.type], (x) => x._id === val);
          let messageId = _.get(
            this.convs[this.type],
            `${index}.last_message.id`,
            null
          );
          if (!messageId) {
            await this.fetchMessages();
            await this.$nextTick();
            this.scrollBottom();
          }

          if (messageId) {
            while (true) {
              let prevSize = _.size(this.messages);
              await this.fetchMessages();
              let mindex = _.findIndex(
                this.messages,
                (x) => x.id === messageId
              );
              if (mindex === -1) {
                if (prevSize === _.size(this.messages)) {
                  break;
                }

                continue;
              }

              if (mindex !== -1) {
                let search = this.escapeRegexp(this.search);
                this.messages[mindex].content.message = this.messages[
                  mindex
                ].content.message.replace(
                  new RegExp(`${search}`, "ig"),
                  "<b>$&</b>"
                );

                setTimeout(
                  () => {
                    let target = document.getElementById("message-" + messageId);
                    this.$refs.scroller.scrollTop = target.offsetTop;
                  },
                  500
                );
                break;
              }
            }
          }
        }
      }

      if (!val) {
        this.changeconv(_.get(_.first(this.convs[this.type]), "_id"));
      }

      this.loadings.messages = false;
      await this.$nextTick();
      this.scrollBottom();
      this.getInquiriesRight();
      this.getRequestRight();
    },
    checkUnread(conv) {
      let fromMe = _.get(conv, "last_message.from_me", true);
      let readed = _.get(conv, "last_message.readed", true);
      return fromMe === false && readed === false;
    },
    escapeRegexp(str) {
      return str.replace(/[-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    },
    stripHtml(value) {
      return value.replace(/<\/?[^>]+>/gi, " ");
    },
    selectEmoji({ data }) {
      let editor = this.$refs.editor.quill;
      let index = editor.getSelection(true);
      editor.insertText(index, data);
    },
    insertBulb(item) {
      if (item.disabled) {
        return;
      }

      let editor = this.$refs.editor.quill;
      let index = editor.getSelection(true);
      editor.insertText(index, item.text);
      item.disabled = true;
    },
    bindEditorEnter() {
      let editor = this.$refs.editor.quill;
      editor.keyboard.bindings[13].unshift({
        key: 13,
        handler: () => {
          this.send();
          return false;
        },
      });
    },
    onScrollMessage() {
      let st = this.$refs.scroller.scrollTop;
      if (st < 1) {
        this.lastScrollTop = st;
        if (this.loadings.messages) {
          return;
        }

        this.loadings.messages = true;
        let lastId = _.get(this.messages, "[0].id");
        let target = document.getElementById("message-" + lastId);
        this.fetchMessages()
          .then(this.$nextTick)
          .then(() => this.$nextTick())
          .finally(() => {
            this.loadings.messages = false;
            this.$refs.scroller.scrollTop = target.offsetTop;
          });
      }
    },
    async onScrollConvs(e) {
      await this.$nextTick();
      let $el = this.$refs.convsList;
      if ($el.scrollHeight - $el.scrollTop < 20) {
        if (this.loadings.convs) {
          return;
        }

        this.loadings.convs = true;
        await this.fetchConvs();
        this.loadings.convs = false;
      }
    },
    toggleEmoji() {
      this.semoji = !this.semoji;
    },
    closeEmoji() {
      this.semoji = false;
    },
    changeconv(id) {
      if (!id) {
        return true;
      }

      if (!this.search && this.conversation_id === id) {
        return;
      }

      if (this.search && this.conversation_id === id) {
        this.onChangeConv(id);
        return;
      }
      this.talkAbout = {};
      this.$router.push("/app/messenger/" + id);
    },
    onSearch() {
      this.filters.convList[this.type].cursor = null;
      this.filters.convList[this.type].filters.search = this.search;
      this.$set(this.convs, this.type, []);
      this.fetchConvs();
    },
    async fetchConvs(type = null) {
      try {
        let data = await this.$apollo.query(
          getLastConversationList,
          this.filters.convList[type || this.type]
        );
        let response = data("**.conversations");
        let list = response.list;
        if (_.size(response.list) === 0) {
          return;
        }

        if (this.search !== "") {
          let search = this.escapeRegexp(this.search);
          for (let item of list) {
            if (_.has(item, "last_message")) {
              let message = item.last_message.content.message;
              message = this.stripHtml(message).replace(
                new RegExp(`${search}`, "ig"),
                "<b>$&</b>"
              );
              item.last_message.content.message = message;
            }
          }
        }

        this.convs[type || this.type].push(...list);
        this.filters.convList[type || this.type].cursor = response.next_cursor;
        return true;
      } catch (e) {
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      }
    },
    async fetchConvDetail() {
      if (!this.conversation_id) return;
      let data = await this.$apollo.query(conversation, {
        _id: this.conversation_id,
      });

      this.sconv = data("**.conversation");
      if (!_.has(this.sconv, "_id")) {
        return this.$router.push("/app/messenger");
      }

      let fromMe = _.get(this.sconv, "last_message.from_me", true);
      let readed = _.get(this.sconv, "last_message.readed", true);
      if (fromMe === false && readed === false) {
        if (this.sconv.muted !== true) {
          this.badge--;
        }

        let index = _.findIndex(
          this.convs[this.type],
          (x) => x._id === this.sconv._id
        );
        _.set(this.convs[this.type], index + ".last_message.readed", true);
        this.$io.emit("conversation:read", { conversation_id: this.sconv._id });
        this.$store.commit("messages/REMOVE_ID", this.sconv._id);
      }
    },
    changeType(type) {
      this.type = type;
    },
    scrollBottom() {
      let el = this.$refs.scroller;
      let cb = () => {
        el.scrollTop = el.scrollHeight;
      };

      this.$nextTick(cb);
    },
    async fetchMessages() {
      try {
        if (!this.conversation_id) return;
        let data = await this.$apollo.query(
          getLastMessagesByUser,
          this.filters.messageList
        );
        let response = data("**.messages");
        if (response.next_cursor) {
          this.filters.messageList.cursor = response.next_cursor;
        }

        if (response.list && _.size(response.list) > 0) {
          this.messages.unshift(...response.list.reverse());
        }
      } catch (e) {
        console.log(e);
      }
    },
    async onMessage(response) {
      let nindex = _.findIndex(
        this.convs["NORMAL"],
        (x) => x._id === response.conversation._id
      );

      let findex = _.findIndex(
        this.convs["FAVORITE"],
        (x) => x._id === response.conversation._id
      );

      if (nindex !== -1) {
        // normalde da varsa
        _.set(
          this.convs["NORMAL"],
          nindex + ".last_message.content.message",
          response.message.content.message
        );
        _.set(this.convs["NORMAL"], nindex + ".last_message.readed", false);
        _.set(this.convs["NORMAL"], nindex + ".last_message.from_me", false);
        _.set(
          this.convs["NORMAL"],
          nindex + ".updated_at",
          moment().format("YYYY-MM-DD HH:mm:ss")
        );
        this.convs["NORMAL"].sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );
        if (response.conversation._id !== this.conversation_id) {
          if (this.convs["NORMAL"][nindex].muted !== true) {
            this.badge++;
          }
        }
      }

      if (nindex === -1) {
        // normalde de yoksa
       let conv = response.conversation;
        this.convs["NORMAL"].unshift(conv);
        if (conv.muted !== true) {
          this.badge++;
        }

        this.convs["NORMAL"].sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );

        if (findex === -1 && conv.favorite === true) {
          this.convs["FAVORITE"].unshift(conv);
          this.convs["FAVORITE"].sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          );
        }
      }

      if (findex !== -1) {
        // favorilerde varsa
        _.set(
          this.convs["FAVORITE"],
          findex + ".last_message.content.message",
          response.message.content.message
        );
        _.set(this.convs["FAVORITE"], findex + ".last_message.readed", false);
        _.set(this.convs["FAVORITE"], findex + ".last_message.from_me", false);
        _.set(
          this.convs["FAVORITE"],
          findex + ".updated_at",
          moment().format("YYYY-MM-DD HH:mm:ss")
        );
        this.convs["FAVORITE"].sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );
      }

      if (response.conversation._id === this.conversation_id) {
        this.messages.push(response.message);
        this.scrollBottom();

        if (nindex !== -1) {
          _.set(this.convs["NORMAL"], nindex + ".last_message.readed", true);
        }

        if (findex !== -1) {
          _.set(this.convs["FAVORITE"], findex + ".last_message.readed", true);
        }

        this.$io.emit("conversation:read", {
          conversation_id: response.conversation._id,
        });

        this.$store.commit("messages/REMOVE_ID", response.conversation._id);
      }
    },
    async emitcb(response) {
        console.log(response);
      if (response.success === false) {
        return this.$bvToast.toast(this.$t(response.message), {
          title: this.$t("Toaster.error-title"),
          variant: "secondary",
          autoHideDelay: 1350,
        });
        return;
      }

      if (response.conversation._id === this.conversation_id) {
        this.messages.push({
          id: response.message.id,
          from_me: true,
          created_at: response.message.created_at,
          content: {
            message: this.chatMessage,
            files: response.message.content.files,
            refs: response.message.content.refs,
            replied: response.message.content.replied,
          },
        });

        this.scrollBottom();
        this.chatMessage = "";
        this.$refs.editor.quill.setText("");
      }

      let index = _.findIndex(
        this.convs[this.type],
        (x) => x._id === response.conversation._id
      );

      if (index === -1) {
        let conv = response.conversation;
        this.convs[this.type].unshift(conv);
        index = 0;
      }

      _.set(
        this.convs[this.type],
        index + ".last_message.content.message",
        response.message.content.message
      );
      _.set(this.convs[this.type], index + ".last_message.readed", true);
      _.set(this.convs[this.type], index + ".last_message.from_me", true);
      _.set(
        this.convs[this.type],
        index + ".updated_at",
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
      this.convs[this.type].sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );

      if (this.type !== "FAVORITE") {
        let findex = _.findIndex(
          this.convs["FAVORITE"],
          (x) => x._id === response.conversation._id
        );
        if (findex !== -1) {
          _.set(
            this.convs["FAVORITE"],
            index + ".last_message.content.message",
            response.message.content.message
          );
          _.set(this.convs["FAVORITE"], index + ".last_message.readed", true);
          _.set(this.convs["FAVORITE"], index + ".last_message.from_me", true);
          _.set(
            this.convs["FAVORITE"],
            index + ".updated_at",
            moment().format("YYYY-MM-DD HH:mm:ss")
          );
          this.convs["FAVORITE"].sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          );
        }
      }

      if (this.type === "FAVORITE") {
        let nindex = _.findIndex(
          this.convs["NORMAL"],
          (x) => x._id === response.conversation._id
        );

        if (nindex !== -1) {
          _.set(
            this.convs["NORMAL"],
            index + ".last_message.content.message",
            response.message.content.message
          );
          _.set(this.convs["NORMAL"], index + ".last_message.readed", true);
          _.set(this.convs["NORMAL"], index + ".last_message.from_me", true);
          _.set(
            this.convs["NORMAL"],
            index + ".updated_at",
            moment().format("YYYY-MM-DD HH:mm:ss")
          );
          this.convs["NORMAL"].sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          );
        }
      }
    },
    async send() {
      if (!this.conversation_id) {
        return;
      }

      if (this.percentages.files !== null) {
        return;
      }

      if (_.size(this.chatMessage) === 0 && _.size(this.files) === 0) {
        return;
      }

      let el = document.createElement("div");
      el.innerHTML = this.chatMessage;

      if (el.innerText === "" && _.size(this.files) === 0) {
        return;
      }

      let options = {
        fetchOptions: {
          onUploadProgress: (progress) => {
            this.percentages.files = Math.round(
              (progress.loaded / progress.total) * 100
            );
          },
        },
      };

      let body = {
        conversation_id: this.conversation_id,
        content: {
          files: [],
          message:
            _.size(this.files) > 0 && _.size(this.chatMessage) === 0
              ? "<div></div>"
              : this.chatMessage,
        },
      };

      if (_.has(this.talkAbout, "id")) {
        body.content.refs = [
          {
            id: this.talkAbout.id,
            type: this.talkAbout.type,
          },
        ];
      }

      let oc = false;
      if (_.size(this.files) > 0) {
        try {
          this.loadings.file = true;
          this.percentages.files = 0;
          let data = await this.$apollo.mutate(
            uploadFile,
            { files: this.files },
            options
          );
          let tempfiles = [];
          let files = data("**.upload");
          for (let index in files) {
            if (files[index].uploaded === false) {
              if (files[index].code === "ERROR.OBJECTIONABLE_CONTENT") {
                oc = true;
              }

              this.$bvToast.toast(files[index].code, {
                title: "Warning",
                variant: "danger",
                autoHideDelay: 4000,
              });
              this.files.splice(index, 1);
              files.splice(index, 1);
              continue;
            }

            if (files[index]) {
              tempfiles[index] = {
                code: files[index].code,
                ext: files[index].ext,
                filename: files[index].filename,
              };
            }
          }

          body.content.files = tempfiles;
        } catch (error) {
          return this.$bvToast.toast(this.$t(error.message), {
            title: this.$t("Toaster.error-title"),
            variant: "danger",
            autoHideDelay: 1350,
          });
        } finally {
          this.loadings.file = false;
          this.percentages.files = null;
          this.files = [];
        }
      }

      if (oc === true) {
        this.messages.push({
          id: Math.random(),
          from_me: true,
          created_at: new Date(),
          content: {
            message: this.$t("messenger.OBJECTIONABLE_CONTENT"),
            files: [],
            refs: [],
            replied: null,
          },
        });

        this.scrollBottom();
        this.chatMessage = "";
        this.$refs.editor.quill.setText("");
        return;
      }

      let tel = document.createElement("div");
      tel.innerHTML = body.content.message;
      if (tel.innerText === "" && _.size(body.content.files) === 0) {
        return;
      }

      if (_.get(this.replied, "id", null)) {
        body.content.replied = this.replied.id;
      }

      this.$io.emit("message", body, this.emitcb);
      this.replied = { id: null, content: null };
      this.talkAbout = {};
      this.bulbs = _.map(this.bulbs, (x) => ({ ...x, disabled: false }));
      return true;
    },
    onTyping(response) {
      let index = _.findIndex(
        this.convs[this.type],
        (x) => x._id === response.conversation._id
      );
      if (index !== -1) {
        this.$set(this.convs[this.type], index, {
          ...this.convs[this.type][index],
          typing: response.typing,
        });
      }
    },
    onRead(response) {
      if (response.conversation._id === this.conversation_id) {
        for (let index in this.messages) {
          this.$set(this.messages, index, {
            ...this.messages[index],
            readed: true,
          });
        }
      }
    },
    setReply(id) {
      let content = _.get(
        _.find(this.messages, (x) => x.id === id),
        "content",
        null
      );
      this.replied = {
        id: id,
        content,
      };
    },
    async delconv(_id) {
      try {
        await this.$apollo.mutate(deleteConversation, { _id: _id });
        if (this.type === "FAVORITE") {
          let findex = _.findIndex(
            this.convs["FAVORITE"],
            (x) => x._id === this.conversation_id
          );
          if (findex !== -1) {
            this.convs["FAVORITE"].splice(findex, 1);
          }

          let nindex = _.findIndex(
            this.convs["NORMAL"],
            (x) => x._id === this.conversation_id
          );
          if (nindex !== -1) {
            this.convs["NORMAL"].splice(nindex, 1);
          }
        }

        if (this.type !== "FAVORITE") {
          let index = _.findIndex(
            this.convs[this.type],
            (x) => x._id === this.conversation_id
          );
          if (index !== -1) {
            this.convs[this.type].splice(index, 1);
          }

          let findex = _.findIndex(
            this.convs["FAVORITE"],
            (x) => x._id === this.conversation_id
          );
          if (findex !== -1) {
            this.convs["FAVORITE"].splice(findex, 1);
          }
        }

        this.sconv = {};
        this.conversation_id = null;
        this.$set(this, "messages", []);
        this.$router.push("/app/messenger");
        return true;
      } catch (error) {
        return this.$bvToast.toast(this.$t("Toaster.not-deleted"), {
          title: this.$t("Toaster.error-title"),
          variant: "error",
          autoHideDelay: 1350,
        });
      }
    },
    makeDelay: function (ms) {
      let timer = 0;
      return function (callback, ...props) {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...props), ms);
      };
    },
    async countUnreadConvs(type) {
      let sresponse = await this.$apollo.query(countUnreads, { type: type });
      this.badge = sresponse("**.countUnreads");
    },
    async onAddToMyFavorites(_id) {
      try {
        let index = _.findIndex(this.convs["FAVORITE"], (x) => x._id === _id);
        if (index !== -1) {
          return true;
        }

        let data = await this.$apollo.mutate(addToMyFavorites, { _id });
        this.convs["FAVORITE"].unshift(data("**.addToMyFavorites"));
        return true;
      } catch (error) {
        return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      }
    },
    async onRemoveFromMyFavorites(_id) {
      try {
        let index = _.findIndex(this.convs["FAVORITE"], (x) => x._id === _id);
        if (index === -1) {
          return true;
        }

        await this.$apollo.mutate(removeFromMyFavorites, { _id });
        this.convs["FAVORITE"].splice(index, 1);
        return true;
      } catch (error) {
        return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      }
    },
    checkInFavorites(_id) {
      return _.findIndex(this.convs["FAVORITE"], (x) => x._id === _id) !== -1;
    },
    async deleteMessage(id) {
      try {
        let index = _.findLastIndex(this.messages, (x) => x.id === id);
        if (index === -1) {
          return true;
        }

        await this.$apollo.mutate(deleteMessageGQL, {
          conversation_id: this.conversation_id,
          id,
        });
        this.messages.splice(index, 1);
        return true;
      } catch (error) {
        return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      }
    },
    onDeleteMessage({ id }) {
      let index = _.findLastIndex(this.messages, (x) => x.id === id);
      if (index !== -1) {
        this.messages.splice(index, 1);
      }
    },
    deleteFile(index) {
      this.files.splice(index, 1);
    },
    async loadSocialAccounts() {
      let now = moment().format("YYYY-MM-DD HH:mm:ss");
      let params = {
        filters: {
          expired_at: {
            gt: now,
          },
        },
        cursor: "",
        limit: 100,
      };

      let accounts = [];
      while (true) {
        let data = await this.$apollo.query(socialAccountsList, params);
        let list = data("**.accountList.list");
        let cursor = data("**.accountList.next_cursor");

        if (_.size(list) === 0) {
          break;
        }

        if (cursor) {
          params.cursor = cursor;
        }

        accounts.push(...list);
      }

      this.socialAccounts = accounts;
    },
    openSocialAccountsModal() {
      this.$refs.socialAccountsModal.open();
    },
    getSocialAccount(type) {
      return _.find(
        this.socialAccounts,
        (x) => x.type === type && moment() < moment(x.expired_at)
      );
    },
    authZoom() {
      let popup = window.open(
        this.API_DOMAIN + "/auth/zoom?token=" + this.GET_USER_INFO.token,
        "_blank",
        "status=1,width=500,height=300"
      );
      let id = null;
      let timer = async () => {
        if (popup && popup.closed) {
          clearInterval(id);
          await this.loadSocialAccounts();
          popup = null;
        }
      };

      id = setInterval(timer, 250);
    },
    async createMeetingZoom() {
      try {
        let params = { provider: "ZOOM" };
        if (this.meeting.laterMeeting === true) {
          if (
            !moment(
              _.get(this, "meeting.form.start.date"),
              "YYYY-MM-DD",
              true
            ).isValid()
          ) {
            return this.$bvToast.toast(this.$t("Toaster.choose-date-start"), {
              title: this.$t("Toaster.info-title"),
              variant: "secondary",
              autoHideDelay: 1350,
            });
            return;
          }

          if (
            !moment(
              _.get(this, "meeting.form.end.date"),
              "YYYY-MM-DD",
              true
            ).isValid()
          ) {
            return this.$bvToast.toast(this.$t("Toaster.choose-date-finish"), {
              title: this.$t("Toaster.info-title"),
              variant: "secondary",
              autoHideDelay: 1350,
            });
            return;
          }

          if (
            !moment(
              _.get(this, "meeting.form.end.time"),
              "HH:mm",
              true
            ).isValid()
          ) {
            return this.$bvToast.toast(this.$t("Toaster.choose-hour-finish"), {
              title: this.$t("Toaster.info-title"),
              variant: "secondary",
              autoHideDelay: 1350,
            });
            return;
          }

          if (
            !moment(
              _.get(this, "meeting.form.start.time"),
              "HH:mm",
              true
            ).isValid()
          ) {
            return this.$bvToast.toast(this.$t("Toaster.choose-hour-start"), {
              title: this.$t("Toaster.info-title"),
              variant: "secondary",
              autoHideDelay: 1350,
            });
            return;
          }

          let startTime = `${moment(this.meeting.form.start.date).format(
            "YYYY-MM-DD"
          )} ${this.meeting.form.start.time}:00`;
          let endTime = `${moment(this.meeting.form.start.date).format(
            "YYYY-MM-DD"
          )} ${this.meeting.form.end.time}:00`;

          if (moment(endTime).diff(moment(startTime)) <= 0) {
            return this.$bvToast.toast(this.$t("Toaster.time-frame-limit"), {
              title: this.$t("Toaster.info-title"),
              variant: "info",
              autoHideDelay: 1350,
            });
            return;
          }

          if (!this.meeting.form.timezone) {
            return this.$bvToast.toast(this.$t("Toaster.choose-timezone"), {
              title: this.$t("Toaster.info-title"),
              variant: "secondary",
              autoHideDelay: 1350,
            });
            return;
          }

          _.set(params, "schedule", {
            start_time: startTime,
            end_time: endTime,
            timezone: this.meeting.form.timezone,
          });
        }

        let data = await this.$apollo.mutate(createMeeting, params);
        let url = data("**.createMeeting.join_url");
        this.chatMessage += ` <a href="${url}"> ${url} </a>`;
        this.$refs.socialAccountsModal.close();
      } catch (error) {
        return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      }
    },
    async block(uid) {
      await this.$apollo.mutate(BLOCK, { uid });
      _.set(this, "sconv.to.blocked", true);
    },
    async unblock(uid) {
      await this.$apollo.mutate(UNBLOCK, { uid });
      _.set(this, "sconv.to.blocked", false);
    },
    setTalkAbout(item, type) {
      item["type"] = type;
      this.talkAbout = item;
      this.$set(this, "files", []);
    },
    mute(_id) {
      this.$io.emit("conversation:mute", { conversation_id: _id }, () => {
        this.sconv.muted = true;
        let index = _.findIndex(this.convs[this.type], { _id });
        if (index !== -1) {
          this.convs[this.type][index].muted = true;
        }
      });
    },

    unmute(_id) {
      this.$io.emit("conversation:unmute", { conversation_id: _id }, () => {
        this.sconv.muted = false;
        let index = _.findIndex(this.convs[this.type], { _id });
        if (index !== -1) {
          this.convs[this.type][index].muted = false;
        }
      });
    },

    async reportUser() {
      if (!_.has(this, "sconv._id")) {
        return false;
      }

      if (_.isNil(this.rtype)) {
        return false;
      }

      try {
        await this.$apollo.mutate(REPORT, {
          conversation_id: this.sconv._id,
          type: this.rtype,
        });
        this.$refs.reportModal.close();
      } catch (error) {
        return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      }
    },
  },

  async created() {
    this.searchDelay = this.makeDelay(500);
    this.$set(this, "sconv", {});
    this.$set(this, "messages", []);
    this.$io.on("message", this.onMessage);
    this.$io.on("conversation:typing", this.onTyping);
    this.$io.on("conversation:read", this.onRead);
    this.$io.on("message:delete", this.onDeleteMessage);

    await this.countUnreadConvs("SELLER");
    await this.countUnreadConvs("BUYER");

    if (this.$route.params.id) {
      this.conversation_id = this.$route.params.id;
      await this.fetchConvDetail();
      this.type = "NORMAL";
      await this.fetchMessages();
      await this.$nextTick();
      this.scrollBottom();
    }

    await this.fetchConvs("NORMAL");
    await this.fetchConvs("FAVORITE");
    await this.loadSocialAccounts();

    if (!this.$route.params.id && this.convs[this.type].length > 0) {
      this.changeconv(_.get(_.first(this.convs[this.type]), "_id", null));
    }

    this.$io.emit("onMessaging", { status: true });
    await this.getInquiriesRight();
    await this.getRequestRight();
  },

  mounted() {
    this.scrollBottom();
    this.bindEditorEnter();

    const mediaModal = document.getElementById("messages-media-modal");
    mediaModal.addEventListener("click", this.onCloseMediaModalOverlay);
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },

  beforeDestroy() {
    const mediaModal = document.getElementById("messages-media-modal");
    mediaModal.removeEventListener("click", this.onCloseMediaModalOverlay);

    this.$io.off("message", this.onMessage);
    this.$io.emit("onMessaging", { status: false });
    this.$io.off("conversation:typing", this.onTyping);
    this.$io.off("conversation:read", this.onRead);
    this.$io.off("message:delete", this.onDeleteMessage);
  },
};
</script>
